import Modal from "react-bootstrap/Modal";
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react'
import Card from 'react-bootstrap/Card';
import axios from "axios";
import { IoMdAlert } from "react-icons/io";
import { MdOutlineArrowBack, MdOutlineDoneOutline } from "react-icons/md";
import Image from 'react-bootstrap/Image';
import { ThreeDots } from 'react-loader-spinner'

const MyRepairs = ({ show, onHide: closeModal, onExit, onShow }) => {

    const [news, setNews] = useState([]);
    const [showsblank, setshowsblank] = useState(false);
    const [showspiner, setShowspiner] = useState(true);

    onExit = () => {
        setshowsblank(false)
        setNews([])
    }

    onShow = () => {


        axios.post("/api/repairworks/user/list_my", {}

        ).then((response) => {
            setShowspiner(false)

            if (response.data === "empty street") {
                setshowsblank(true)
                return; 
            }

            if (response.data.length === 0) {
                setshowsblank(true)
            } else {
                setNews(response.data);
            }


        }).catch(error => {
            setShowspiner(false)
        })

    }


    return (
        <>
            <Modal show={show} onHide={closeModal} onExit={onExit} onShow={onShow}
                aria-labelledby="rep_my" fullscreen
                centered>
                <Modal.Header>
                    <Modal.Title className="user-profile-remontworks-title" onClick={closeModal}><MdOutlineArrowBack /> Повернутися до мій 15-80</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <div class="d-inline">
                        <Image height={36} width={36} src='/my_repair.png' className="m-2" alt='...'></Image>
                    </div><div class="d-inline">
                        <span className="">Мої ремонтні роботи</span>
                    </div>



                    {showsblank &&

                        <Card className='shadow bg-body rounded'>
                            <Card.Body>

                                <div class="notfound p-4">

                                    <section class="text-center"><span className='rep2-icon'><MdOutlineDoneOutline /></span></section>
                                    <section class="text-center pt-1"><span className='fw-normal'>За Вашою адресою ремонтних робіт не знайдено.</span></section>

                                </div>
                            </Card.Body>
                        </Card>
                    }
                    {showspiner &&
                        <div className='d-grid d-flex justify-content-center p-5'>

                            <ThreeDots
                                height="140"
                                width="140"
                                radius="15"
                                color="#4fa94d"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />

                        </div>
                    }
                    {news && news.map((d, index) => (
                        <div key={index}>
                            <div className='row'>
                                <Col lg={10} className="p-1">
                                    <Card className='h-100 shadow-sm bg-body rounded repeir-card' >

                                        <div class="card-header">
                                            <div class="col d-flex align-items-center">
                                                <span class="remontworks-icon me-3" style={{ backgroundImage: `url( ${'/repeirworks/' + d.problem_id + '.png'} )` }}>
                                                    {d.is_emergency != null &&
                                                        <div className='repeirs-alarm'><IoMdAlert /></div>
                                                    }
                                                </span>

                                                <div className='me-auto pt-2'>
                                                    <h5 className='fw-bold'>{d.problem}</h5>
                                                    <h6 class="mt-2 m2"> {d.reason}</h6>
                                                </div>

                                            </div>
                                            <div class="col">
                                                <h6 class="m1 py-1">{d.reason}</h6>
                                            </div>
                                        </div>
                                        <Card.Body>
                                            <div className='col border-bottom repeir-date-m m1'>
                                                <div class="nowrap" style={{fontSize: '15px'}}>Дата початку: {d.date_beg}</div>
                                                <div class="nowrap" style={{fontSize: '15px'}}>Планова дата закінчення: {d.date_plan}</div>
                                            </div>
                                            {/* <div class="mt-1 m1">{d.reason}</div> */}

                                            <div class="col d-flex align-items-start me-auto">

                                                <div>
                                                    {d.addresses.split(';').map(ad => <div className='py-1'>{ad}</div>)}
                                                </div>
                                            </div>


                                        </Card.Body>

                                        <div class="card-footer text-muted">
                                            <div class="mt-2">
                                            <div class="mt-2 col"><b>Примітка: </b></div>
                   
                                            <div>{d.event_text}</div>

                                                <div class="mt-2 col"><b>Виконавець:</b></div>
                                               <div>{d.executor}</div>

                                               </div>
                                        </div>
                                    </Card>
                                </Col>

                                <Col lg={2} className="m2 p-1">
                                    <Card className='h-100 shadow-sm bg-body rounded repeir-card'>
                                        <Card.Body>

                                            <Card.Text>

                                                <div className="card-img-overlay d-flex flex-column"
                                                     style={{fontSize: '16px'}}>
                                                    <div><span className="fw-bold">Дата початку:</span>
                                                        <div className="nowrap">{d.date_beg}</div>
                                                        {/* <div>{d.date_beg.split(' ')[1]}</div>*/}
                                                    </div>

                                                    <div className="mt-auto"><span className="fw-bold"
                                                                                   style={{fontSize: '16px'}}>Планова дата закінчення:</span>
                                                        <div className="nowrap">{d.date_plan}</div>
                                                        {/* <div>{d.date_plan.split(' ')[1]}</div>*/}


                                                    </div>
                                                </div>


                                            </Card.Text>


                                        </Card.Body>
                                    </Card>
                                </Col>
                            </div>
                        </div>
                    ))}
                </Modal.Body>


            </Modal>
        </>
    )
}

export default MyRepairs