import React, { useState } from "react";
import { FcNext } from "react-icons/fc";

function ReadMore({ text }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const MAX_LENGTH = 80; // Максимальная длина текста, которая будет видна

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
    
        {isExpanded || text.length <= MAX_LENGTH
          ? text
          : `${text.slice(0, MAX_LENGTH)}...`}
     
      {text.length > MAX_LENGTH && (
        <span onClick={toggleReadMore}>
          {isExpanded ?  '' : <span className="nowrap" style={{cursor: 'pointer'}}><FcNext size={16} /></span>}
        </span>

      )}
     
    </>
  );
}

export default ReadMore;