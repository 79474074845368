//import { Popup } from 'react-leaflet/Popup'
//import Popup from 'react-leaflet-editable-popup';
import Col from 'react-bootstrap/Col';
//import { FaLocationArrow } from "react-icons/fa";
import { MdOutlineDoneAll, MdDone, MdOutlineRemoveDone } from "react-icons/md";
import { BiRotateRight } from "react-icons/bi";
import { BsClock } from "react-icons/bs";
import { TbArrowForward } from "react-icons/tb";
import { popupContent } from "./popupContent"
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import mapService from '../../services/mapService';
import { ThreeDots } from 'react-loader-spinner'

const PopupModal = ({ show, handleClose, data }) => {

    const [d, setD] = useState([]);
    const [spiner, setSpiner] = useState(true);

    const onShow = () => {

        mapService.getMapProblem(data).then((response) => {
            setD(response.data)
            setSpiner(false)
        }).catch(error => {
        })

    }

    const onExit = () => {
        setSpiner(true)
        setD([])
    }

    // useEffect(() => {

    //     mapService.getMapProblem(data).then((response) => {
    //         setD(response.data)
    //         setSpiner(false)
    //     }).catch(error => {
    //     })

    // }, []);

    return (
        <Modal centered show={show} onHide={handleClose} onExit={onExit} onShow={onShow} fullscreen='md-down'
            aria-labelledby="popuprequestmodal">


            <Modal.Body>
                {!spiner &&
                    <div style={popupContent}>
                        <Row className={`request-popup-header shadow-sm m-1 p-1 ${d.status_id === 1 || d.status_id === 6 ? "request-popup-header-col-ye" : ""}`}>
                            <div className='request-popup-title-h'><b>Звернення № {d.request_num}</b></div>
                            <div className='pb-3 request-popup-title'>від {d.created_date}</div>
                            <div className='request-popup-header-t'><b>{d.problem_name}</b></div>
                            <div className='request-popup-header-t2 pt-2 pb-2'>{d.reason_name}</div>

                        </Row>
                        <Row className='m-1 mt-2 pt-1 pb-1 request-popup-status shadow-sm'>
                            <Col sm={12} className="request-popup-header-title">Статус:</Col>
                            {d.status_id === 0 &&
                                <Col><span className='statusicon_wait2 pe-1'><BiRotateRight /></span>{d.status}</Col>
                            }
                            {d.status_id === 1 &&
                                <Col><span className='statusicon_wait pe-1'><BsClock /></span>{d.status}</Col>
                            }
                            {d.status_id === 2 &&
                                <Col><span className='statusicon pe-1'><MdOutlineDoneAll /></span>{d.status}</Col>
                            }
                            {d.status_id === 3 &&
                                <Col><span className='statusicon pe-1'><MdDone /></span>{d.status}</Col>
                            }
                            {d.status_id === 4 &&
                                <Col><span className='statusicon_RemoveDone pe-1'><MdOutlineRemoveDone /></span>{d.status}</Col>
                            }
                            {d.status_id === 5 &&
                                <Col><span className='statusicon_no pe-1'><MdDone /></span>{d.status}</Col>
                            }
                            {d.status_id === 6 &&
                                <Col><span className='statusicon_no pe-1'><TbArrowForward /></span>{d.status}</Col>
                            }
                        </Row>
                        <Row className='m-1 mt-2 pb-2 request-popup-status shadow-sm'>
                            <Row className='pt-1'>
                                <Col sm={12} className="request-popup-header-title">Адреса:</Col>
                                <Col sm={12}>{d.street_name}, {d.house_num}</Col>
                            </Row>
                        </Row>

                        <Row className='m-1 mt-2 pb-2 request-popup-status shadow-sm'>
                            <Row className='pt-1'>
                                <Col sm={12} className="request-popup-header-title"> Планова дата виконання:</Col>
                                <Col sm={12}>{d.date_plan}</Col>
                            </Row>

                            {(d.final_date != null) && <>
                                <Row className='pt-1'>
                                    <Col sm={12} className="request-popup-header-title">Дата закриття:</Col>
                                    <Col sm={12}>{d.final_date}</Col>
                                </Row>
                            </>}

                        </Row>
                        {(d.executor != null) && <>
                            <Row className='m-1 mt-2 request-popup-status shadow-sm'>
                                <Row className='pt-1 pb-2'>
                                    <Col sm={12} className="request-popup-header-title">Виконавець:</Col>
                                    <Col sm={12}>{d.executor}</Col>
                                </Row>
                            </Row>
                        </>}

                    </div>
                }
                {spiner &&
                    <div className='d-grid d-flex justify-content-center p-5'>
                        <ThreeDots
                            height="130"
                            width="130"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                }
            </Modal.Body>

            <div className='d-grid d-flex justify-content-center p-2'>
                <Button variant="link" onClick={handleClose}>
                    Закрити
                </Button>
            </div>

        </Modal >
    )
}

export default PopupModal;