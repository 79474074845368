import React, { useState, useCallback } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Alert } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from "react-hook-form";
import RememberPaswordModal from "./RememberPasswordComponent";
import RegistrationModal from "./Registartion_modal";
import { useNavigate } from 'react-router-dom';
import {
    useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BiShow } from "react-icons/bi";

const Login = ({ show, onHide: closeModal, onExit, onShow }) => {


    let navigate = useNavigate();
    const [passwordShown, setPasswordShown] = useState(false);
    const [showCaptcha, setShowCaptcha] = useState(true);
    const [cccc, setShowLoginModal] = useState(false);
    const [errorText, setErrorText] = useState('');
    const handleCloseLoginModal = () => setShowLoginModal(false);

    const [cccc2, setShowRegModal] = useState(false);
    const handleCloseLoginModalR = () => setShowRegModal(false);

    const rememberPassword = () => {
        setShowCaptcha(false)
        closeModal()
        setShowLoginModal(true)
    }

    const hundleRegistation = () => {
        setShowCaptcha(false)
        closeModal()
        setShowRegModal(true)
    }

    const url = "/api/auth/signin";

    const [spiner, setSpiner] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    onExit = () => {

        if (setShowAlert) {
            setShowAlert(false)
        }
        if (spiner) {
            setSpiner(false)
        }

        reset()
    }

    onShow = () => {
        if (!showCaptcha) {
            setShowCaptcha(true)
        }
    }

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm({
        defaultValues: {
            username: "",
            password: ""
        }
    });


    const LoginReCaptchaComponent = () => {
        const { executeRecaptcha } = useGoogleReCaptcha();
    
        const handleReCaptchaVerify = useCallback(async (data2) => {
            setShowAlert(false);
            setErrorText('');
            setSpiner(true);
    
            const token = await executeRecaptcha('login');
          
            if (!token) {
                setSpiner(false);
                setErrorText('Не вдалося отримати токен reCAPTCHA. Перезавантажте сторінку та спробуйте ще.');
                setShowAlert(true);
                return;
            }
    
            try {
                const response = await axios.post(url, { username: data2.username, password: data2.password, token: token });
               
                if (response.status === 200) {
                    if (response.data.accessToken) {
                        localStorage.setItem("user", JSON.stringify(response.data));
                        closeModal();
                        navigate("/profile");
                    } else {
                        if (response.data.message === "captha_error") {
                           setErrorText("Помилка reCAPTCHA. Перезавантажте сторінку та спробуйте ще раз.")
                        } else { setErrorText("Помилка під час авторизації (200). Перезавантажте сторінку та спробуйте ще раз."); }
                                        
                    }
                }
              
            } catch (error) {

                if (error !== undefined){

                    if (error.status === 401 && error.response.data.message !== undefined) {
                        setErrorText(error.response.data.message);
                    } else {
                        setErrorText(error.message);
                    }
                }  else {
                    setErrorText('Помилка під час авторизації (error). Перезавантажте сторінку та спробуйте ще раз.');
                }

         

            } finally{
                setSpiner(false);
                setShowAlert(true);
            }
            
        }, [executeRecaptcha]);
    
        return (
            <>
                <div className="text-right pb-1 p-2">
                    <span className="login_modal_button arrow-right" onClick={() => rememberPassword()}>Забули пароль</span>
                </div>
                <div className="text-right pb-3 p-2" style={{lineHeight : '0.9'}}>
                    <span className="login_modal_button arrow-right" onClick={() => { closeModal(); navigate('/techsupport'); }}>Допомога у вирішенні проблем, пов'язаних з авторизацією</span>
                </div>
                <Row className="py-4">
                    <Col className="d-flex justify-content-center">
                        <Button type="submit" className="login_modal_button3 p-2 py-2" variant="primary" onClick={handleSubmit(handleReCaptchaVerify)}
                            disabled={spiner}>
                            {spiner ? (
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            ) : (
                                <>Увійти</>
                            )}
                        </Button>{' '}
                    </Col>
                </Row>
                <div className="row text-center pt-2">
                    <span className="login_modal_button2 arrow-right" onClick={() => hundleRegistation()}>Зареєструватися</span>
                </div>
            </>
        );
    };
    

    return (
        <>
            <RememberPaswordModal
                show={cccc}
                onHide={handleCloseLoginModal}
            />

            <RegistrationModal
                show={cccc2}
                onHide={handleCloseLoginModalR}
            />

            <Modal show={show} onHide={closeModal} onExit={onExit} onShow={onShow} fullscreen='md-down'
                aria-labelledby="loginform"
                centered>

                <Modal.Header closeButton>
                    <Modal.Title>Вхід до Мій 15-80</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={handleSubmit} className="p-2">
                        <Alert className="text-center loginFormAlert" show={showAlert} variant='danger'>{errorText}</Alert>

                        <div className="p-2">
                            <label for="username" className="label">Адреса електронної пошти</label>
                            <input
                                {...register("username", {
                                    required: "Обов’язкове поле!",
                                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                                })}
                                type="text" className="form-control" id="username" aria-describedby="username" />
                            {errors.username && (
                                <p className="labal-alert">
                                    <div>{errors.username.message}
                                        {errors.username && errors.username.type === "pattern" && (
                                            <p className="labal-alert"><div>Невірний формат email</div></p>
                                        )}
                                    </div>
                                </p>
                            )}
                        </div>

                        <div className="p-2">
                            <label for="password" className="label">Пароль</label>
                            <div className="pass-wrapper">
                                <input
                                    {...register("password", { required: "Обов’язкове поле!" })}
                                    type={passwordShown ? "text" : "password"} className="form-control" id="password" aria-describedby="password" />

                                <i onClick={() => setPasswordShown(passwordShown ? false : true)}><BiShow /></i>{" "}
                            </div>
                            {errors.password && (
                                <p className="labal-alert">
                                    <div>{errors.password.message}</div>
                                </p>
                            )}
                        </div>

                        {showCaptcha && <>
                            <LoginReCaptchaComponent />
                        </>}

                    </Form>

                    <Row>
                        <div className="captcha_p pt-5 col">
                            <p>Цей сайт захищено технологією reCAPTCHA, до нього застосовуються <a href="https://policies.google.com/privacy">Політика конфіденційності</a> та <a href="https://policies.google.com/terms">Умови використання</a> Google</p>
                        </div>
                    </Row>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default Login