import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useForm, Controller } from "react-hook-form";
//import { Alert, Form } from "react-bootstrap";
import AuthService from "../../services/AuthService";
import React, { useState, useEffect } from 'react'
import Spinner from "react-bootstrap/Spinner";
import Card from 'react-bootstrap/Card';
import AsyncSelect from 'react-select/async';
import Container from 'react-bootstrap/Container';
import axios from "axios";
import Select, { } from 'react-select';
import House_letter_data from '../../letter_house.json';
//import MaskedInput from "react-input-mask"
import { PatternFormat } from "react-number-format";
import { ThreeDots } from 'react-loader-spinner'
//import { FaBullseye } from "react-icons/fa";
import Alert from 'react-bootstrap/Alert';


const UserSettingsModal = ({ show, onHide: closeModal, onExit, onShow }) => {

    const p_u = /^[АаБбВвГгҐґДдЕеЄєЖжЗзИиІіЇїЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщьЮюЯяʼ'"]+$/

    const [showE, setShowE] = useState(false);
    const [error_text, setError_text] = useState('');

    const [gender_f, setGender_f] = useState(false);
    const [gender_m, setGender_m] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedStreet, setSelectedStreet] = useState(null);
    const [showstreeterror, setshowstreeterror] = useState(false);
    const [showstreeterror2, setshowstreeterror2] = useState(false);
    const [spiner, setSpiner] = useState(false);
    const [showspiner, setShowspiner] = useState(true);

    const [selectedRn, setSelectedRn] = useState(null);
    const [rn_list, setRn_list] = useState(null);

    const [house_p, setHouse_p] = useState(null);

    const [corpus, setCorpus] = useState('');

    const {
        setError,
        register,
        resetField,
        formState: { errors },
        getValues,
        handleSubmit,
        reset,
        control,
        setValue
    } = useForm({
        mode: 'onChange',
        criteriaMode: 'firstError',
        shouldFocusError: true,
        shouldUnregister: true
    });

    onExit = () => {
        // setShowAlert(true)
        // setShowAlert2(false)
        // setSpiner(false)
        reset()
        setGender_f(false)
        setGender_m(false)
        setSelectedStreet(null)
        setSpiner(false)
        setShowE(false)
    }


    const handleClick_gender_f = () => {
        if (setGender_m) {
            setGender_m(false)
        }
        setGender_f(true)
        setValue('gender_ID', '2');
    }

    const handleClick_gender_m = () => {
        if (setGender_f) {
            setGender_f(false)
        }
        setGender_m(true)
        setValue('gender_ID', '1');
    }


    const loadOptions = async (str) => {
        if (str.length >= 3) {
            const response = await axios.post("/api/repairworks/streetlist",
                { str: str });
            return response.data;
        }
    };

    const return_street_from_value = async (str) => {
        setIsLoading(true)
        const response = await axios.post("/api/repairworks/streetlist_from_id",
            { str: str })
        setSelectedStreet(response.data)
        setIsLoading(false)
    };

    onShow = () => {

        AuthService.profile_card().then((response) => {

            setShowspiner(false)
            if (response.data.house != null) {
                let isnum = /^\d+$/.test(response.data.house.slice(-1));

                if (isnum) {
                    setValue("house", response.data.house)
                } else {

                    let selectedProduct = House_letter_data
                        .find(data => data.value === response.data.house.slice(-1));

                    setHouse_p(selectedProduct)
                    setValue("house", response.data.house.slice(0, -1))
                }
            }


            setValue("surname", response.data.surname)
            setValue("name", response.data.name)
            setValue("patronymicua", response.data.patronymicua)
            setValue("mobile_phone", response.data.mobilePhone)
            setValue("additional_phone", response.data.additionalPhone)
            setValue("gender_ID", response.data.gender_ID)
            setValue("flat", response.data.flat)
            setValue("corpus", response.data.corpus)

            if (response.data.gender_ID === 2) { setGender_f(true) }
            if (response.data.gender_ID === 1) { setGender_m(true) }

            if (response.data.notification === 1) { setValue("notification", true) }
            if (response.data.notification2 === 1) { setValue("notification2", true) }


            if (response.data.street_id != null) {
                return_street_from_value(response.data.street_id)
            }

            //   loadRninselect(response.data.street_id, response.data.rn_id)


            if (response.data.street_id !== null && response.data.street_id !== undefined){
            axios.post("/api/general/getDistrictsFromStreetId/" + response.data.street_id, {}

            ).then((response2) => {

                setRn_list(response2.data)

                setSelectedRn(response2.data
                    .find(data => data.id === response.data.rn_id))

            }).catch(error => {
                setIsLoading(false)
            })
                }

        }).catch(error => {

        })

    }


    const loadRninselect = (street_id) => {

        setSelectedRn(null)

        if (street_id === null) {
            setRn_list(null)
            return
        }

        axios.post("/api/general/getDistrictsFromStreetId/" + street_id.value, {}

        ).then((response) => {

            setRn_list(response.data)

            if (response.data.length === 1) {
                setSelectedRn(response.data[0])
            }

        }).catch(error => {

        })
    }


    const onSubmit = (data) => {

        if (selectedStreet === null) {

            setshowstreeterror(true)

            return
        }

        if (selectedRn === null) {

            setshowstreeterror2(true)

            return
        }


        let n = null;
        let n2 = null;

        if (data.notification) { n = 1 }
        if (data.notification2) { n2 = 1 }
        setSpiner(true)

        let house = data.house;
        if (house_p != null) {
            house = house + '' + house_p.value
        }
        axios.post("/api/user/profilecard_card", {

            surname: data.surname,
            name: data.name,
            patronymicua: data.patronymicua,
            gender_ID: data.gender_ID,
            mobilePhone: data.mobile_phone.replace("(", "").replace(")", "").replaceAll("-", ""),
            additionalPhone: data.additional_phone,
            street_id: selectedStreet.value,
            street_name: selectedStreet.labeltype + ' ' + selectedStreet.label,
            notification: n,
            notification2: n2,
            flat: data.flat,
            house: house,
            rn_id: selectedRn.id,
            rn: selectedRn.district_name,
            corpus: data.corpus
        }).
            then((responce) => {

                if (responce.data.message === "ok") {
                    closeModal();
                } else {
                    setError_text(responce.data.message)
                    setShowE(true)
                    setSpiner(false)
                }
            })

    }

    useEffect(() => {
        setshowstreeterror(false)
    }, [selectedStreet]);

    const handleCorpus = (v) => {

        // let isnum = /^\d+$/.test(v);

        // if (isnum || v === '') {

        //     if (v.length > 4) {
        //         setValue('corpus', (getValues("corpus").slice(0, -1)));
        //     }

        // } else {
        //     setValue('corpus', (getValues("corpus").slice(0, -1)));
        // }
        if (v.length > 5) {
            setValue('corpus', (getValues("corpus").slice(0, -1)));
        }
    }

    const handlehouse = (v) => {

        let isnum = /^\d+$/.test(v);

        if (isnum || v === '') {

            if (v.length > 3) {
                setValue('house', (getValues("house").slice(0, -1)));
            }

        } else {
            setValue('house', (getValues("house").slice(0, -1)));
        }
    }

    const handleFlat = (v) => {

        // let isnum = /^\d+$/.test(v);

        // if (isnum || v === '') {

        //     if (v.length > 4) {
        //         setValue('flat', (getValues("flat").slice(0, -1)));
        //     }

        // } else {
        //     setValue('flat', (getValues("flat").slice(0, -1)));
        // }
    }




    return (
        <>
            <>
                <Modal show={show} fullscreen='xl-down' size='xl' onHide={closeModal} onExit={onExit} centered onShow={onShow}>
                    <Modal.Header closeButton>
                        <Modal.Title>Налаштування</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                    <Alert variant="warning" onClose={() => setShowE(false)} show={showE} dismissible>
        <Alert.Heading> УВАГА! Дані не збереглися</Alert.Heading>
        <p>
         {error_text}
        </p>
      </Alert>

                        <Card.Body>
                            <Card.Text>

                                <Container>

                                    {showspiner &&
                                        <div className='d-grid d-flex justify-content-center spiner-user-profile'>

                                            <ThreeDots
                                                height="140"
                                                width="140"
                                                radius="15"
                                                color="#4fa94d"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />

                                        </div>
                                    }
                                    {!showspiner && <>
                                        <div className="row">
                                            <div className="col-md-6 mb-1">
                                                <label for="surname" className="label">Прізвище <span className="labal-alert">*</span></label>
                                                <input
                                                    {...register("surname", { 
                                                          pattern: {
                                                            value: p_u,
                                                            message: 'Лише літери української мови',
                                                        },
                                                        required: "Обов’язкове поле!" })}
                                                    type="text" className="form-control" id="surname" aria-describedby="surname" />
                                                {errors.surname && (
                                                    <p className="labal-alert"><div>{errors.surname.message}</div></p>
                                                )}
                                            </div>
                                            <div className="col-md-6 mb-1">
                                                <label for="name" className="label">Ім'я <span className="labal-alert">*</span></label>
                                                <input
                                                    {...register("name", {   pattern: {
                                                        value: p_u,
                                                        message: 'Лише літери української мови',
                                                    },  required: "Обов’язкове поле!" })}
                                                    type="text" className="form-control" id="name" aria-describedby="name" />
                                                {errors.name && (
                                                    <p className="labal-alert"><div>{errors.name.message}</div></p>
                                                )}
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <label for="patronymicua" className="label">По батькові <span className="labal-alert">*</span></label>
                                                <input
                                                    {...register("patronymicua", {    pattern: {
                                                        value: p_u,
                                                        message: 'Лише літери української мови',
                                                    }, required: "Обов’язкове поле!" })}
                                                    type="text" class="form-control" id="patronymicua" aria-describedby="patronymicua" />
                                                {errors.patronymicua && (
                                                    <p className="labal-alert"><div>{errors.patronymicua.message}</div></p>
                                                )}
                                            </div>



                                            <div className="col-md-6 mb-3">
                                                <label class="label pe-3">Стать <span className="labal-alert">*</span></label>
                                                <input {...register("gender_ID", { required: "Оберіть свою стать" })} type="input" className="d-none" />

                                                <div className="nowrap">
                                                    <div class="mb-3 form-check form-check-inline">
                                                        <input

                                                            // {...register("gender_f", { })}
                                                            type="checkbox" class="form-check-input" id="gender_f" checked={gender_f} onClick={handleClick_gender_f} />

                                                        <label for="gender_f" className='label-user-profile-gender'>
                                                            Жіноча
                                                        </label>
                                                    </div>

                                                    <div class="mb-3 form-check form-check-inline">
                                                        <input

                                                            // {...register("gender_m", { })}
                                                            type="checkbox" className="form-check-input" id="gender_m" checked={gender_m} onClick={handleClick_gender_m} />

                                                        <label for="gender_m" className='label-user-profile-gender'>
                                                            Чоловіча

                                                        </label>
                                                    </div>

                                                </div>

                                                {errors.gender_ID && (
                                                    <p className="labal-alert mb-3"><div>{errors.gender_ID.message}</div></p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 mb-3">

                                                <label for="mobile_phone" class="label">Телефон <span className="labal-alert">*</span></label>

                                                <Controller
                                                    name="mobile_phone"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required: true,
                                                        pattern: {
                                                            value: /[^_]+$/,
                                                            message: "Введіть коректно телефон"
                                                        },
                                                    }}
                                                    render={({ field }) => (

                                                        <PatternFormat
                                                            className="form-control"
                                                            value={field.value}
                                                            format="(###)###-##-##"
                                                            mask="_"
                                                            allowEmptyFormatting
                                                            onChange={field.onChange}
                                                        />

                                                    )}
                                                />




                                                {/* <label for="mobile_phone" class="label">Телефон <span className="labal-alert">*</span></label>
                                            <input
                                                {...register("mobile_phone", { required: "Обов’язкове поле!" })}
                                                type="text" class="form-control" id="mobile_phone" aria-describedby="mobile_phone"
                                            /> */}
                                                {errors.mobile_phone && (
                                                    <p className="labal-alert"><div>{errors.mobile_phone.message}</div></p>
                                                )}
                                            </div>
                                            <div className="col-md-6 mb-3">

                                                <label for="additional_phone" className="label">Додатковий телефон</label>
                                                <input
                                                    {...register("additional_phone", {})}
                                                    type="text" className="form-control" id="additional_phone" aria-describedby="additional_phone" />
                                            </div>
                                        </div>
                                        <div className=" border-bottom mb-1 mt-1 label-need-alarm">Моя адреса</div>

                                        <div className="row">

                                            <div className="col-md-6 mb-1">
                                                <label for="street" className="label">Вулиця <span className="labal-alert">*</span></label>
                                                <AsyncSelect id='street' classNamePrefix="mySelect"
                                                    // defaultOptions
                                                    defaultValue={selectedStreet}
                                                    cacheOptions

                                                    loadOptions={(str) => loadOptions(str)}
                                                    isLoading={isLoading}
                                                    onChange={(value) => { setSelectedStreet(value); loadRninselect(value) }}
                                                    value={selectedStreet}
                                                    closeMenuOnSelect
                                                    loadingMessage={() => 'Шукаю, зачекайте...'}
                                                    placeholder={'Оберіть вулицю'}

                                                    noOptionsMessage={() => 'Введіть мініум 3 літери або уточніть пошук'}
                                                    isClearable

                                                    getOptionLabel={(option) => `${option.labeltype} ${option.label}`}

                                                    styles={{
                                                        option: (styles, state) => ({ // fixed
                                                            ...styles,
                                                            fontSize: state.isSelected ? 21 : 19,
                                                            color: state.isSelected ? 'black' : '#444444'
                                                        }),
                                                    }}

                                                    theme={(theme) => ({
                                                        ...theme,

                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#E1E4E8',
                                                            primary: '#c2e6d3',
                                                        },
                                                    })}
                                                />
                                                {showstreeterror && (
                                                    <p className="labal-alert"><div>Оберіть вулицю!</div></p>
                                                )}
                                                {/* <Alert className="non-street-user-profile" show={showstreeterror} variant='danger'>Оберіть вулицю!</Alert> */}
                                            </div>




                                            <div className="col-md-4 mb-1">
                                                <label for="rn" className="label">Район <span className="labal-alert">*</span></label>
                                                <Select id='rn' classNamePrefix="mySelect"

                                                    options={rn_list}
                                                    onChange={(value) => { setSelectedRn(value); setshowstreeterror2(false) }}

                                                    isSearchable={false}
                                                    isLoading={isLoading}

                                                    value={selectedRn}


                                                    loadingMessage={() => 'Шукаю, зачекайте...'}
                                                    placeholder={''}

                                                    noOptionsMessage={() => ''}

                                                    getOptionLabel={(option) => `${option.district_name}`}
                                                    getOptionValue={option => option.id}
                                                    styles={{
                                                        option: (styles, state) => ({ // fixed
                                                            ...styles,
                                                            fontSize: state.isSelected ? 21 : 19,
                                                            color: state.isSelected ? 'black' : '#444444'
                                                        }),
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,

                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#E1E4E8',
                                                            primary: '#c2e6d3',
                                                        },
                                                    })}
                                                />

                                                {showstreeterror2 && (
                                                    <p className="labal-alert"><div>Оберіть район!</div></p>
                                                )}
                                                {/* <Alert className="non-street-user-profile" show={showstreeterror2} variant='danger'>Оберіть район!</Alert> */}
                                                {/* <Alert className="non-street-user-profile nowrap" show='false' variant='danger'>Оберіть район!</Alert> */}
                                            </div>
                                        </div>



                                        <div className="row">
                                            <div className="col-6 col-lg-3 col-xxl-2 col-xl-3 col-md-4 mb-1">
                                                <label for="house" className="label nowrap">Будинок <span className="labal-alert">*</span></label>
                                                <input
                                                    {...register("house", {
                                                        required: "Обов’язкове поле!",
                                                        onChange: (e) => handlehouse(e.target.value)

                                                    })}
                                                    type="text" class="form-control" id="house" aria-describedby="house" />
                                                {errors.house && (
                                                    <p className="labal-alert"><div>{errors.house.message}</div></p>
                                                )}
                                            </div>

                                            <div className="col-6 col-lg-3 col-xxl-2 col-xl-3 col-md-4">
                                                <label for="house" class="label">Префікс</label>
                                                <Select classNamePrefix="mySelect"
                                                    aria-labelledby="aria-label"
                                                    isClearable
                                                    onChange={(value) => { setHouse_p(value) }}
                                                    placeholder={''}
                                                    options={House_letter_data}
                                                    value={house_p}
                                                    defaultOptions
                                                    closeMenuOnSelect
                                                    noOptionsMessage={() => ''}

                                                    styles={{
                                                        option: (styles, state) => ({ // fixed
                                                            ...styles,
                                                            fontSize: state.isSelected ? 21 : 19,
                                                            color: state.isSelected ? 'black' : '#444444'
                                                        }),
                                                    }}

                                                    theme={(theme) => ({
                                                        ...theme,

                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#E1E4E8',
                                                            primary: '#c2e6d3',
                                                            primary50: '#E1E4E8',
                                                        },
                                                    })}

                                                />
                                            </div>

                                            <div className="col-6 col-lg-3 col-xxl-2 col-xl-3 col-md-4">
                                                <label for="corpus" className="label nowrap">Корпус</label>
                                                <input
                                                    {...register("corpus", {
                                                        onChange: (e) => handleCorpus(e.target.value)
                                                    })}
                                                    type="text" className="form-control" aria-describedby="corpus"

                                                />

                                            </div>


                                        </div>
                                        <div className="row">
                                            <div className="col-6 col-lg-3 col-xxl-2 col-xl-3 col-md-4 mb-3">
                                                <label for="flat" className="label">Квартира</label>
                                                <input
                                                    {...register("flat", {
                                                        onChange: (e) => handleFlat(e.target.value)
                                                    })}
                                                    type="text" className="form-control" id="flat" aria-describedby="flat" />
                                            </div>
                                        </div>

                                        <div className="mb-1 form-check">
                                            <input

                                                {...register("notification", {})}
                                                type="checkbox" class="form-check-input" id="notification" />
                                            {/* {errors.accept && (
                                        <p className="labal-alert"><div>{errors.accept.message}</div></p>
                                    )} */}

                                            <label for="notification" className='label-need-alarm'>
                                                Отримувати e-mail сповіщення про мої звернення
                                            </label>
                                        </div>

                                        <div class="mb-1 form-check">
                                            <input

                                                {...register("notification2", {})}
                                                type="checkbox" class="form-check-input" id="notification2" />
                                            {/* {errors.accept && (
                                        <p className="labal-alert"><div>{errors.accept.message}</div></p>
                                    )} */}

                                            <label for="notification2" className='label-need-alarm'>
                                                Отримувати e-mail сповіщення про ремонтні роботи

                                            </label>
                                        </div>
                                        <div className="text-center border-top">
                                            <label class="label">Поля, позначені <span className="labal-alert">*</span>, є обов’язковими для заповнення</label>
                                        </div>

                                        <Row className="py-1">
                                            <Col className="d-flex justify-content-center">
                                                <Button className="btn btn-primary login_modal_button3 py-2" variant="primary" onClick={handleSubmit(onSubmit)} type="submit"
                                                    disabled={spiner}>
                                                    {spiner ? (
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    ) : (
                                                        <>Зберігти</>
                                                    )}
                                                </Button>{' '}
                                            </Col></Row>
                                    </>}
                                </Container>

                            </Card.Text>
                        </Card.Body>



                    </Modal.Body>
                </Modal>
            </>
        </>
    )
}

export default UserSettingsModal