
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from "react";
import { BsFillInfoSquareFill } from "react-icons/bs";

const alarm1 = () => {
    return (
        <div className="container">
            
             
                    <Row className="alert-message alert-message-success">
                   
                    <Col sm={12} md={1} className="d-flex align-items-center justify-content-center">
                   
                    <span className="icon-alert">
                                <BsFillInfoSquareFill size={60}/>
                            </span>
                    
                        </Col>
                       
                        <Col sm={12} md={11} className="pt-md-0 pt-4" style={{fontSize: '0.968rem', fontWeight : '500', textAlign: 'justify'}}>
                            <p> 
                                {/* У контакт-центра 15-80 новий телеграм-бот «Запоріжжя 1580»
                                Він буде інформувати про ремонтні роботи за вашою адресою
                                Перейти в телеграм-бот можна за <a href="https://t.me/colcentrebot" target="_blank" rel="noreferrer">посиланням</a>  або з вашого кабінету користувача на нашому сайті
                               */}
                              
                                Телеграм-бот «Запоріжжя 1580» від контакт-центру 15-80 інформує про ремонтні роботи за вашою адресою. Перейти до нього можна за <a href="https://t.me/colcentrebot" target="_blank" rel="noreferrer">посиланням</a> або з особистого кабінету на нашому сайті.
                              
                               </p>
                               </Col>
                    
                    </Row>
                
           
        </div>
    );
}

const alarm2 = () => {


    return (
        // <div className="container">
        //     <div className="row">
        //         <div className="col-sm-12">
        //             <div className="col d-flex align-items-center alert-message alert-message-success">
        //                 <section className="text-center">
        //                     <div className="icon-alert">
        //                         <BsFillInfoSquareFill size={60}/>
        //                     </div>
        //                 </section>

        //                 <div className='me-auto ps-3'>
        //                     <p>
        //                         Для скорочення часу подачі звернення у вас є можливість зареєструватись в особистому кабінеті на порталі КЦ 1580 з подальшим поданням звернення в електронному вигляді.</p>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>

        <div className="container">
            
             
        <Row className="alert-message alert-message-success">
       
        <Col sm={12} md={1} className="d-flex align-items-center justify-content-center">
       
        <span className="icon-alert">
                    <BsFillInfoSquareFill size={60}/>
                </span>
        
            </Col>
           
            <Col sm={12} md={11} className="pt-md-0 pt-4" style={{fontSize: '0.968rem', fontWeight : '500', textAlign: 'justify'}}>
                <p> Для скорочення часу подачі звернення у вас є можливість зареєструватись в особистому кабінеті на порталі КЦ 1580 з подальшим поданням звернення в електронному вигляді
                   </p>
                   </Col>
        
        </Row>
    

</div>
    );
}

const alarm3 = () => {
    return (
        <div className="container">
            
             
                    <Row className="alert-message alert-message-success">
                   
                    <Col sm={12} md={1} className="d-flex align-items-center justify-content-center">
                   
                    <span className="icon-alert">
                                <BsFillInfoSquareFill size={60}/>
                            </span>
                    
                        </Col>
                       
                        <Col sm={12} md={11} className="pt-md-0 pt-4" style={{fontSize: '0.968rem', fontWeight : '500', }}>
                            <span className='d-flex align-items-start justify-content-start pb-1'><b>ВАЖЛИВО!</b></span>
                            
                            <p>
В Запоріжжі триває процес перейменування вулиць.
Наш веб-портал дозволяє користувачу  подати звернення, навіть коли він користується при вводі інформації старою назвою вулиці. Але просимо звернути увагу, що відображатися в заявці та профілі користувача буде вже нова назва вулиці.

                               </p>
                               </Col>
                    
                    </Row>
                
           
        </div>
    );
}

const alarmBloks = {
    alarm1,
    alarm2,
    alarm3,
};
export default alarmBloks;