function CharHeader({title, subtitle}) {
    return (
        <>
            <div style={{ fontSize: '18px', textAlign: 'center', }} className='d-flex justify-content-center align-items-center flex-wrap'>
                <div className="w-100" style={{ fontWeight: 900, }}>{title}</div>
                <span style={{ fontSize: '14px', }}>
                {subtitle}
                </span>
            </div>
        </>
    );
}
export default CharHeader;