import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
//import React, { useState } from 'react'
import { RiPhoneFill } from "react-icons/ri";
import { FaComments } from "react-icons/fa";
import { FaCity } from "react-icons/fa";

function Bloks() {


    return (


        <Container className='pb-1 pt-4' id='section2'>
            <section className='text-center py-md-4'><h1>Піклуйся про Запоріжжя!</h1></section>


            <Row className="text-center row-cols-1 row-cols-lg-3 m2">
                <Col className='py-3 d-flex justify-content-center'>
                    <Card>
                        <Card.Body>
                            <div className="circle">
                                <RiPhoneFill size={65} />
                            </div>
                            <Card.Text className='iconmain-text'>
                                Повідомляй про проблемні питання Запоріжжя
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className='py-3 d-flex justify-content-center'>
                    <Card>
                        <Card.Body>

                            <div className="circle">
                                <FaComments size={65} />
                            </div>

                            <Card.Text className='iconmain-text'>
                                Пропонуй шляхи покращення різних сфер міста
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className='py-3 d-flex justify-content-center'>
                    <Card>
                        <Card.Body>

                            <div className="circle">
                                <FaCity size={65} />
                            </div>
                            <Card.Text className='iconmain-text'>
                                Дбай про благоустрій та культурний простір
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <div className='m1'>
                <Row className="text-center row-cols-1 row-cols-lg-3">
                    <Col className='pt-1 pb-3 d-flex justify-content-center'>
                        <Card className='h-100 w-100'>
                            <Card.Body>
                                <div class="col d-flex align-items-center">
                                    <section class="text-center">
                                        <div className="circle2">
                                            <RiPhoneFill size={60} />
                                        </div>
                                    </section>

                                    <div className='me-auto ps-3'>
                                    
                                        Пропонуй шляхи покращення різних сфер міста
                                       
                                    </div>

                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='pb-3 d-flex justify-content-center'>
                        <Card className='h-100 w-100'>
                            <Card.Body>
                                <div class="col d-flex align-items-center ">
                                    <section class="text-center">
                                        <div className="circle2">
                                            <FaComments size={60} />
                                        </div>
                                    </section>

                                    <div className='me-auto ps-3'>

                                            Повідомляй про проблемні питання Запоріжжя
                                     
                                    </div>

                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='d-flex justify-content-center'>
                        <Card className='h-100 w-100'>
                            <Card.Body>
                                <div class="col d-flex align-items-center">
                                    <section class="text-center">
                                        <div className="circle2">
                                            <FaCity size={60} />
                                        </div>
                                    </section>

                                    <div className='me-auto ps-3'>
                                    
                                       Дбай про благоустрій та культурний простір
                                     
                                    </div>

                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

        </Container>
    );
}

export default Bloks;