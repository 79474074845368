import Container from 'react-bootstrap/Container';
import Page from './page';
import { TbError404 } from "react-icons/tb";
import React, { useEffect } from "react";

function NotFound() {

    useEffect(() => {
        document.title = 'Контакт–Центр м. Запоріжжя 1580';
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container>
        <Page />
        <section class="text-center py-3"><span className='rep-icon'><TbError404 /></span></section>
        <section class="text-center py-3 py-md-5"><h1 className='display-4 fw-normal'>Сторінка не знайдена</h1></section>
        </Container>

    );
}

export default NotFound;