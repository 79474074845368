import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useForm } from "react-hook-form";
import { Alert, Form } from "react-bootstrap";
import AuthService from "../../services/AuthService";
import { MdOutlineDone } from "react-icons/md";
import React, { useState } from 'react'
import Spinner from "react-bootstrap/Spinner";
import Container from 'react-bootstrap/Container';
import { BiShow } from "react-icons/bi";

const ChangePasswordUserProfile = ({ show, onHide: closeModal, onExit }) => {

    const [showAlert, setShowAlert] = useState(true)
    const [showAlert2, setShowAlert2] = useState(false)
    const [spiner, setSpiner] = useState(false);

    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShown2, setPasswordShown2] = useState(false);
    const [passwordShown3, setPasswordShown3] = useState(false);

    const {
        register,
        formState: { errors },
        getValues,
        handleSubmit,
        reset
    } = useForm({
        mode: 'onChange',
        criteriaMode: 'firstError',
        shouldFocusError: true,
        shouldUnregister: true
    });

    onExit = () => {
        setShowAlert(true)
        setShowAlert2(false)
        setSpiner(false)
        reset()
    }

    const onSubmit = (data) => {
        setSpiner(true)
        AuthService.changeuserpassword(data.password, data.oldpassword).then((responce) => {

            if (responce.data.message === "ok") {
                setShowAlert(false)
                setShowAlert2(false)
            }
            if (responce.data.message === "fail") {
                setShowAlert(true)
                setShowAlert2(true)
            }

            setSpiner(false)
        })

    }
    return (
        <>
            <Modal show={show} onHide={closeModal} onExit={onExit}
                aria-labelledby="rememberpassword" fullscreen='md-down'
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Змінити пароль</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form onSubmit={handleSubmit(onSubmit)}>

                            <Alert className="p-2 loginFormAlert" show={showAlert2} variant='danger'> Було введено невірний старий пароль</Alert>
                            {showAlert ? (
                                <>
                                    <Row>
                                        <Col sm={12} className="mb-3">
                                            <label for="oldpassword" className="label">Старий пароль</label>
                                            <div className="pass-wrapper">
                                                <input
                                                    {...register("oldpassword", {
                                                        required: "Обов’язкове поле!",
                                                        validate: {
                                                            min6: (value) => value.length > 5
                                                        }
                                                    })}
                                                    type={passwordShown ? "text" : "password"} className="form-control" id="oldpassword"
                                                    aria-describedby="oldpassword" />
                                                    <i onClick={() => setPasswordShown(passwordShown ? false : true)}><BiShow /></i>{" "}
                                            </div>

                                            {errors.oldpassword && errors.oldpassword.type === "min6" && (
                                                <p className="labal-alert"><div>Поле повинно буди від 6 символів</div></p>
                                            )}
                                            {errors.oldpassword && (
                                                <p className="labal-alert">
                                                    <div>{errors.oldpassword.message}</div>
                                                </p>)}

                                        </Col>
                                        <Col sm={12} className="mb-3">

                                            <label for="name" className="label">Новий пароль</label>
                                            <div className="pass-wrapper">
                                                <input
                                                    {...register("password", {
                                                        required: "Обов’язкове поле!",
                                                        validate: {
                                                            min6: (value) => value.length > 5,
                                                            max10: (value) => value.length < 26
                                                        }
                                                    })}

                                                    type={passwordShown2 ? "text" : "password"} className="form-control" id="password"
                                                    aria-describedby="password" />
                                                    <i onClick={() => setPasswordShown2(passwordShown2 ? false : true)}><BiShow /></i>{" "}
                                            </div>
                                            {errors.password && errors.password.type === "min6" && (

                                                <p className="labal-alert"><div>Поле повинно буди від 6 символів</div></p>
                                            )}
                                            {errors.password && errors.password.type === "max10" && (
                                                <p className="labal-alert"><div>Поле повинно бути до 25 символів</div></p>
                                            )}
                                            {errors.password && (
                                                <p className="labal-alert">
                                                    <div>{errors.password.message}</div>
                                                </p>)}

                                        </Col>
                                        <Col sm={12} className="mb-3">

                                            <label for="passwordConfirmation" className="label">Новий пароль ще раз</label>
                                            <div className="pass-wrapper">
                                                <input

                                                    {...register("passwordConfirmation", {
                                                        required: "Обов’язкове поле!",
                                                        validate: {
                                                            matchesPreviousPassword: (value) => {
                                                                const { password } = getValues();
                                                                return password === value || "Паролі не співпадають!";
                                                            }
                                                        }
                                                    })}

                                                    type={passwordShown3 ? "text" : "password"} className="form-control" id="passwordConfirmation"
                                                    aria-describedby="passwordConfirmation" />
                                                <i onClick={() => setPasswordShown3(passwordShown3 ? false : true)}><BiShow /></i>{" "}
                                            </div>
                                            {errors.passwordConfirmation && (

                                                <p className="labal-alert"><div>{errors.passwordConfirmation.message}</div></p>
                                            )}
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <div className="block">
                                        <div className="d-flex justify-content-md-center row">

                                            <div className="col-12">
                                                <span className="rememberPaswordResult"><MdOutlineDone /></span>
                                            </div>
                                            <div className="col-12 rememberPaswordResult2">
                                                Новий пароль змінено успішно.
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {showAlert && (
                                <>


                                    <div className="d-flex justify-content-center pb-3">

                                        <Button variant="primary" type="submit" disabled={spiner} className='login_modal_button3 p-2 py-2'>
                                            {spiner ? (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                <>Змінити</>
                                            )}
                                        </Button>{' '}

                                    </div>

                                </>
                            )}
                        </Form>
                    </Container>
                </Modal.Body>


            </Modal>
        </>
    )
}

export default ChangePasswordUserProfile