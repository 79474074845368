import L from "leaflet";

const defaultMarker = (item, key) => new L.icon(
  
  {
    iconUrl: 'map/'+key+'/' + item + '.webp',
    iconSize: [50, 50],
   //iconAnchor: [21, 5]
  }

);

export { defaultMarker };