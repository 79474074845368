import Modal from "react-bootstrap/Modal";
import React, { useState, useCallback, useRef } from "react"
import { useForm } from "react-hook-form";
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card';
import AuthService from "../services/AuthService";
import {
    useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Button from "react-bootstrap/Button";
import '../register.css'
import { FiAlertCircle } from "react-icons/fi";
import { BiShow } from "react-icons/bi";
import Row from 'react-bootstrap/Row';


const Registartion_modal = ({ show, onHide: closeModal, onExit, onShow }) => {

    const guid = useRef("")
    const p_u = /^[АаБбВвГгҐґДдЕеЄєЖжЗзИиІіЇїЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщьЮюЯяʼ'"]+$/

    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShown2, setPasswordShown2] = useState(false);
    const [showCaptcha, setShowCaptcha] = useState(true);
    //const [variant, setvariant] = useState("");
    const [message, setMessage] = useState("");
    const [showmessage, setshowmessage] = useState(false);
    const [showmessage_fail, setshowmessage_fail] = useState(false);
    const [spiner, setSpiner] = useState(false);
    const [errorText, setErrorText] = useState('Сталася помилка');
    const [errorr, setErrorr] = useState(false)

    const [emailOk, setemailOk] = useState(false);

    onExit = () => {

        setshowmessage(false)
        setshowmessage_fail(false)
        setErrorr(false)
        setErrorText('Сталася помилка')
        setShowCaptcha(false)
        reset()
    }

    onShow = () => {
        if (!showCaptcha){
            setShowCaptcha(true)
        }
    }

    const YourReCaptchaComponent = () => {
        const { executeRecaptcha } = useGoogleReCaptcha();
        const handleReCaptchaVerify = useCallback(async (data) => {

            if (!emailOk) {
                setError('email', { type: 'isExistEmail', message: 'Клієнт з таким email вже існує' });
                return
            }

            setSpiner(true)

            if (!executeRecaptcha) {

                setSpiner(false)
                setshowmessage_fail(true)
                console.log('Execute recaptcha not yet available');
                return;
            }

            const token = await executeRecaptcha('register');

            AuthService.register(data.email, data.email, data.password, data.name, data.surname, token).then(

                (response) => {

                    if (response.data.message === 'ok') {

                        guid.current = response.data.guid

                 //       setvariant('success')
                        setMessage(response.data.description)
                        setshowmessage(true)

                    } else {

                        if (response.data.message === "captha_error") {
                            setErrorText("Помилка капчи. перезавантажте сторінку та спробуйте ще")
                        }

                        guid.current = ''
                        setshowmessage_fail(true)
                        setErrorr(true)

                    }

                    setSpiner(false)

                },
                (error) => {
                    setshowmessage_fail(true)
                    setSpiner(false)
                    setErrorr(true)
                }
            )

        }, [executeRecaptcha]);

        return (

            <>
                <div className="d-flex justify-content-center">
                    {!spiner ? (

                        <button type="submit" class="btn btn-primary login_modal_button3 p-2 py-2" onClick={handleSubmit(handleReCaptchaVerify)} >Зареєструватися</button>

                    ) : (

                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className="visually-hidden">Loading...</span><span> Зареєструватися</span>
                        </Button>

                    )}
                </div>
            </>

        )

    };

    const {
        setError,
        register,
        formState: { errors },
        getValues,
        handleSubmit,
        reset
    } = useForm({
        mode: 'onChange',
        criteriaMode: 'firstError',
        shouldFocusError: true,
        shouldUnregister: true
    });

    const isExist = () => {

        if (errors.email === undefined) {

            AuthService.existEmail(getValues().email).then(

                (response) => {

                    if (response.data.message === 'exist') {

                        setError('email', { type: 'isExistEmail', message: 'Клієнт з таким email вже існує' });
                        setemailOk(false)
                    } else {
                        setemailOk(true)
                    }
                },
                (error) => {
                }
            )
        }
    }

    const handleResendConfm = () => {

        if (guid.current === '') {

            return
        }

        //пересылка письма регистрации
        AuthService.resendEmailvertification(guid.current).then(

            (responce) => {

                if (responce.status === '200') {

                }

            },
            (error) => {
                closeModal()
            }
        )
    }


    return (
        <>
            <Modal show={show} fullscreen='xl-down' size='lg' onHide={closeModal} onExit={onExit} onShow={onShow} centered> 
                <Modal.Header closeButton>

                    <Modal.Title>Реєстрація</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <Container>


                        {/* <Card.Header className='registrationTitle'>
                           
                            </Card.Header> */}
                        <Card.Body>
                            <Card.Text>
                                {/* <Card.Title>
                                        РЕЄСТРАЦІЯ
                                        <hr />
                                    </Card.Title> */}

                                {showmessage_fail ? <>
                                    <Alert variant='danger' onClose={() => setshowmessage_fail(false)} dismissible>
                                        Сталася помилка рєестрації, спробуйте зареєструватися пізніше.
                                    </Alert>
                                </>
                                    : <>
                                    </>}

                                {showmessage ? (
                                    <>
                                        <div>
                                            <p>{message}</p>
                                            <hr />
                                            <p>Якщо ви не отримали повідомлення, натиснить <span className="ps-2 textx" onClick={handleResendConfm}>відправити повторно</span></p>
                                        </div>
                                    </>
                                ) : (
                                    <>


                                        <form onSubmit={handleSubmit} className="p-2">
                                            <div className="bd-callout bd-callout-info">


                                                <div class="col d-flex align-items-center">
                                                    <section class="text-center">
                                                        <div className="icon-alert">
                                                            <FiAlertCircle size={40} />
                                                        </div>
                                                    </section>

                                                    <div className='me-auto ps-3'>

                                                        <div className='upload_alert pb-1'>УВАГА! Прізвище та ім'я потрібно заповнювати українською мовою!</div>


                                                    </div>
                                                </div>


                                            </div>

                                            <div className="row">
                                                <div className="col-md-6 mb-4">
                                                    <label for="surname" class="label">Прізвище</label>
                                                    <input
                                                        {...register("surname", { pattern: {
                                                            value: p_u,
                                                            message: 'Лише літери української мови',
                                                        }, required: "Обов’язкове поле!" })}
                                                        type="text" class="form-control" id="surname" aria-describedby="surname" />
                                                    {errors.surname && (
                                                        <p className="labal-alert"><div>{errors.surname.message}</div></p>
                                                    )}
                                                </div>
                                                <div className="col-md-6 mb-4">
                                                    <label for="name" class="label">Ім'я</label>
                                                    <input
                                                        {...register("name", { pattern: {
                                                            value: p_u,
                                                            message: 'Лише літери української мови',
                                                        }, required: "Обов’язкове поле!" })}
                                                        type="text" class="form-control" id="name" aria-describedby="name" />
                                                    {errors.name && (
                                                        <p className="labal-alert"><div>{errors.name.message}</div></p>
                                                    )}
                                                </div>

                                            </div>
                                            
                                            <div className="row">
                                                <div className="col-md-6 mb-4">
                                                    <label for="email" class="label">Адреса електронної пошти</label>
                                                    <input
                                                        {...register("email", {

                                                            required: "Обов’язкове поле!",

                                                            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                                                        }
                                                        )}

                                                        type="text" class="form-control" id="email" aria-describedby="email"
                                                        onBlur={isExist}
                                                    />

                                                    {errors.email && errors.email.type === "pattern" && (
                                                        <p className="labal-alert"><div>Невірний формат email</div></p>
                                                    )}

                                                    {errors.email && (
                                                        <p className="labal-alert"><div>{errors.email.message}</div></p>
                                                    )}
                                                    {errors.isExistEmail && (
                                                        <p className="labal-alert"><div>{errors.isExistEmail.message}</div></p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mb-4">
                                                    <label for="password" class="label">Пароль</label>

                                                    <div className="pass-wrapper">
                                                        <input

                                                            {...register("password", {

                                                                required: "Обов’язкове поле!",

                                                                validate: {
                                                                    min6: (value) => value.length > 5,
                                                                    max10: (value) => value.length < 26
                                                                }

                                                            })}

                                                            type={passwordShown ? "text" : "password"}
                                                            class="form-control" id="password" aria-describedby="password" />
                                                        <i onClick={() => setPasswordShown(passwordShown ? false : true)}><BiShow /></i>{" "}
                                                    </div>



                                                    {errors.password && errors.password.type === "min6" && (
                                                        <p className="labal-alert"><div>Поле повинно бути від 6 символів</div></p>
                                                    )}
                                                    {errors.password && errors.password.type === "max10" && (
                                                        <p className="labal-alert"><div>Поле повинно бути до 25 символів</div></p>
                                                    )}


                                                    {errors.password && (
                                                        <p className="labal-alert"><div>{errors.password.message}</div></p>
                                                    )}

                                                </div>
                                                <div className="col-md-6 mb-4">
                                                    <label for="confirmPassword" class="label">Повторіть пароль</label>
                                                    <div className="pass-wrapper">
                                                        <input

                                                            {...register("passwordConfirmation", {
                                                                required: "Обов’язкове поле!",
                                                                validate: {
                                                                    matchesPreviousPassword: (value) => {
                                                                        const { password } = getValues();
                                                                        return password === value || "Паролі не співпадають!";
                                                                    }
                                                                }
                                                            })}


                                                            type={passwordShown2 ? "text" : "password"}
                                                            class="form-control" id="confirmPassword" aria-describedby="confirmPassword" />
                                                        <i onClick={() => setPasswordShown2(passwordShown2 ? false : true)}><BiShow /></i>{" "}
                                                    </div>
                                                    {errors.passwordConfirmation && (
                                                        <p className="labal-alert"><div>{errors.passwordConfirmation.message}</div>

                                                        </p>
                                                    )}

                                                </div>
                                            </div>
                                            <div class="mb-4 form-check">
                                                <input

                                                    {...register("accept", { required: "Погодьтесь з умовами!" })}
                                                    type="checkbox" class="form-check-input" id="accept" />
                                                {errors.accept && (
                                                    <p className="labal-alert"><div>{errors.accept.message}</div></p>
                                                )}

                                                <label for="accept" className='label-registration'>
                                                    <div className="pb-2">
                                                        - Я погоджуюсь зі збереженням та обробкою персональних даних
                                                        згідно <a href="http://zakon4.rada.gov.ua/laws/show/2297-17" target="_blank" rel="noreferrer">
                                                            Закону України «Про захист персональних даних»&nbsp;
                                                        </a>  та підтверджую, що не розміщуватиму інформацію з обмеженим доступом та будь-яку іншу інформацію або дані окрім публічних
                                                    </div>

                                                    <div className="pb-2">
                                                        - Я погоджуюсь отримувати відповідь в електронному вигляді
                                                    </div>
                                                    <div className="pb-2">
                                                        - Я ознайомлений(а) з <a href="/регламент.pdf" target="_blank">регламентом роботи</a>
                                                    </div>
                                                    <div>
                                                        -  Я ознайомлений(а) з <a href="/класифікатор заявок кц 1580.pdf" target="_blank">Класифікатором заявок мешканців м. Запоріжжя</a>  з питань життєдіяльності міста
                                                    </div>
                                                </label>
                                            </div>

                                            {errorr && <p className="labal-alert p-2"><div>{errorText}</div></p>
                                            }

                                            {showCaptcha && <>
                                                {/* <GoogleReCaptchaProvider reCaptchaKey="6LfiC1ghAAAAAIh6aNPHwnscnMWbBzNYc8FPW8n4" language="uk-UA"> */}

                                                    <YourReCaptchaComponent />
                                                {/* </GoogleReCaptchaProvider> */}
                                            </>}


                                        </form>

                                    </>

                                )}

                            </Card.Text>
                        </Card.Body>


                    </Container>
                    <Row>
                        <div className="captcha_p pt-5 col">
                            <p>Цей сайт захищено технологією reCAPTCHA, до нього застосовуються <a href="https://policies.google.com/privacy">Політика конфіденційності</a> та <a href="https://policies.google.com/terms">Умови використання</a> Google</p>
                        </div>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Registartion_modal