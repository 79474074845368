import { useNavigate } from 'react-router-dom';
import Image from 'react-bootstrap/Image'
import LoginModal from "../Login";
import React, { useState } from "react";
import AuthService from "../../services/AuthService";
import { ImNewspaper } from "react-icons/im";

function FooterTop() {

    let navigate = useNavigate();
    const [showLoginModal, setShowLoginModal] = useState(false);


    const handleMy1580 = () => {
        const user = AuthService.getCurrentUser();

        if (user) {
            navigate('/profile')
        }
        else {
            setShowLoginModal(true)
        }

    }

    return (

        <>

            <LoginModal
                show={showLoginModal}
                onHide={() => setShowLoginModal(false)}
            />
            <div className='container py-3'>

                <div className="row text-center d-grid d-flex justify-content-center">

                    <div className="col-4 col-md-4 col-xxl-2 col-xl-2 col-lg-2 text-footer-text" onClick={() => { navigate('/proportal') }}>
                        <Image height={40} width={40} src='/pro_portal.png' className='img-fluid shadow-4 icon-footer-top' alt='...'></Image>
                        <div>Про портал</div>
                    </div>
                    <div className="col-4 col-md-4 col-xxl-2 col-xl-2 col-lg-2 text-footer-text" onClick={() => { navigate('/remontworks') }}>
                        <Image height={40} width={40} src='/repair.png' className='img-fluid shadow-4 icon-footer-top' alt='...'></Image>
                        <div>Ремонтні
                            роботи</div>
                    </div>
                    <div className="col-4 col-md-4 col-xxl-2 col-xl-2 col-lg-2 text-footer-text" onClick={() => { navigate('/requests') }}>
                        <Image height={40} width={40} src='/letter.svg' className='img-fluid shadow-4 icon-footer-top' alt='...'></Image>
                        <div>
                            Звернення мешканців
                        </div>
                    </div>
                    <div className="col-4 col-md-4 col-xxl-2 col-xl-2 col-lg-2 text-footer-text" onClick={() => { navigate('/result') }}>
                        <Image height={40} width={40} src='/done.svg' className='img-fluid shadow-4 icon-footer-top' alt='...'></Image>
                        <div>
                            Статистика
                        </div>
                    </div>
                    <div className="col-4 col-md-4 col-xxl-2 col-xl-2 col-lg-2 text-footer-text" onClick={handleMy1580}>
                        <Image height={40} width={40} src='/profile2.png' className='img-fluid shadow-4 icon-footer-top' alt='...'></Image>
                        <div>
                            Мій <span className="nowrap">15-80</span>
                        </div>
                    </div>
                    <div className="col-4 col-md-4 col-xxl-2 col-xl-2 col-lg-2 text-footer-text" onClick={() => { navigate('/news') }}>
                        <ImNewspaper color='#0f5e34' size={40} className='img-fluid shadow-4 icon-footer-top'/>
                        {/* <Image height={40} width={40} src='/sms.png' className='img-fluid shadow-4 icon-footer-top' alt='...'></Image> */}
                        <div>
                            Новини
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default FooterTop;