import Modal from "react-bootstrap/Modal";
import {Blocks} from 'react-loader-spinner'
import {FaTelegramPlane} from "react-icons/fa";
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import {TbRefresh} from "react-icons/tb";
import React, {useState} from 'react'
import Card from 'react-bootstrap/Card';
import axios from "axios";
import Image from "react-bootstrap/Image";
import Placeholder from 'react-bootstrap/Placeholder';
import Spinner from 'react-bootstrap/Spinner';


const Telegram_settings = ({show, onHide: closeModal, onExit, onShow}) => {

    const [spiner, setSpiner] = useState(false);
    const [status, setStatus] = useState('');
    const [keyToken, setkeyToken] = useState({});

    const [isLoading, setLoading] = useState(false);

    const [showButtonTestMode, setShowButtonTestMode] = useState(false);

    onExit = () => {
    }

    onShow = () => {
        handlerefresh();
    }

    const handlerefresh = () => {
        setSpiner(true)
        axios.post("/api/user/getbottoken", {}
        ).then((response) => {
            setkeyToken(response.data)
            if (response.data.active === 0) {
                setStatus("З'ЄДНАНО")

                axios.post("/api/user/isShowtestmode", {}
                ).then((response) => {
                    if (response.data.message === 'show') {
                        setShowButtonTestMode(true);
                    } else
                    {
                        setShowButtonTestMode(false);
                    }
                }).catch(error => {
                    
                })

            } else {
                setStatus("РОЗ'ЄДНАНО")
            }
            setSpiner(false)
        }).catch(error => {
            setSpiner(false)
        })
    }

    const handleSendTestMessage = () => {
        setLoading(true)
        axios.post("/api/user/botsendtestmesssage", {}
        ).then((response) => {
            
            setLoading(false)
        }).catch(error => {
            setLoading(false)
        })

    }

    const handleUnlinkBot = () => {

        setSpiner(true)
        axios.post("/api/user/unlinkBot", {}
        ).then((response) => {

            if (response.status === 200){
                handlerefresh();
            }
        }).catch(error => {

        })

    }

    const turnOntestMode = () => {
        axios.post("/api/user/setBotTestmode", {}
        ).then((response) => {
            setShowButtonTestMode(false);
            alert("Тестовий режим увімкнено");
        }).catch(error => {
            setLoading(false)
        })
    }


    const handleUrlbot = () => {
       window.open('https://t.me/colcentrebot?start=' + keyToken.token, '_blank');
     //    window.open('https://t.me/zp1580test_bot?start=' + keyToken.token, '_blank');

         closeModal()
    }
 
    return (
        <>
            <>
                <Modal show={show} fullscreen='xl-down' size='xl' onHide={closeModal} onExit={onExit} centered
                       onShow={onShow}>
                    <Modal.Header closeButton>
                        <Modal.Title><FaTelegramPlane size={40} color="#3390ec"/><span
                            className="ps-3">Telegram Чат-бот</span></Modal.Title>
                    </Modal.Header>

                    <Modal.Body>


                        <h4 className="bd-title d-flex justify-content-start align-items-start">
                            <span onClick={() => {
                                handlerefresh()
                            }}
                                  className='user-refresh-icon ps-2'><TbRefresh size={25}/></span>
                        </h4>

                        <Card className='shadow bg-body rounded py-4'>
                            <Card.Body className='d-flex justify-content-between align-items-start'>


                                <div className="d-none d-md-flex d-flex justify-content-center">
                                    <Image height={110} width={110} src='/hero-logo.jpg'
                                           className='img-fluid shadow-4 icon-footer-top' alt='...'></Image>
                                </div>


                                <div className="p-1 me-12 me-md-auto">
                                    <div className="m-1 h4">
                                        <div className="d-md-none d-flex justify-content-center">
                                            <Image height={80} width={80} src='/hero-logo.jpg'
                                                   className='img-fluid shadow-4' alt='...'></Image>
                                        </div>
                                        Стан зв'язку з обліковим записом Telegram:
                                    </div>
                                    <div className="d-md-none d-flex justify-content-center pt-0">

                                        {spiner && <>
                                            <div class="d-flex justify-content-center pb-4">
                                                <Blocks
                                                    visible={true}
                                                    height="50"
                                                    width="50"
                                                    ariaLabel="blocks-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass="blocks-wrapper"
                                                />
                                            </div>

                                        </>}
                                        {!spiner && <>
                                            <div className="h2">
                                                <Badge pill bg={keyToken.active === 0 ? "success" : "secondary"}
                                                       className="nohead">
                                                    {status}
                                                </Badge>
                                            </div>
                                        </>}


                                    </div>
                                    <div className="mt-4">

                                        {spiner && <>
                                            <Placeholder xs={6}/> <Placeholder xs={8}/>
                                        </>}

                                        {keyToken.active === 0 && !spiner && <>

                                          


                                            <Button
      variant="outline-success"
      className='m-4' size="sm"
      disabled={isLoading}
      onClick={!isLoading ? handleSendTestMessage : null}
    >
        {isLoading && 
         <Spinner style={{marginRight: '10px'}}
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
          
        />}
      {isLoading ? 'Відправляю ...' : 'Надіслати тестове повідомлення'}
    </Button>



                                            <Button onClick={()=>{handleUnlinkBot()}} className='m-4' variant='outline-danger'
                                                   size="sm"
                                            > РОЗ'ЄДНАТИ ОБЛІКОВИЙ ЗАПИС
                                            </Button>
                                        </>}

                                        {keyToken.active === 1 && !spiner && <>

                                            <Button onClick={handleUrlbot} variant="outline-success" className='m-4'
                                                    size="sm">
                                                З'ЄДНАТИ ОБЛІКОВИЙ ЗАПИС
                                            </Button>
                                        </>}
                                    </div>


                                </div>
                                <div className="p-1 me-12 d-none d-md-flex">

                                    <div className="h2 mt-1">
                                        {spiner && <>
                                            <div class="d-flex justify-content-center pb-4">
                                                <Blocks
                                                    visible={true}
                                                    height="100"
                                                    width="100"
                                                    ariaLabel="blocks-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass="blocks-wrapper"
                                                />
                                            </div>

                                        </>}
                                        {!spiner && <>
                                            <Badge pill bg={keyToken.active === 0 ? "success" : "secondary"}
                                                   className="nohead">
                                                {status}
                                            </Badge>
                                        </>}
                                    </div>


                                </div>


                            </Card.Body>

                            





                            {keyToken.active === 0 &&  showButtonTestMode &&
                                <div style={{marginTop: '100px !important'}} className="position-absolute bottom-0 end-0 pe-3 p-1">
                                <span onClick={turnOntestMode} style={{fontSize: '11px', textDecoration: 'underline', cursor: 'pointer'}}>Увімкнути тестовий функціонал</span>
                                </div>
                           
                            }

                        </Card>
                    </Modal.Body>
                </Modal>
            </>
        </>
    )
}

export default Telegram_settings