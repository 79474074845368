import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { useForm } from "react-hook-form";
import { MdOutlineDone } from "react-icons/md";
import { useCookies } from 'react-cookie';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import Pagetitle from './Pagetitle';
import { BiShow } from "react-icons/bi";
import { scroller } from 'react-scroll'

const ResetPasswordConfirm = () => {

    const url = "/api/auth/resetpassword";
    const url_ch = "/api/auth/resetpassword_ch";

    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShown2, setPasswordShown2] = useState(false);
    const [cookies] = useCookies(['XSRF-TOKEN']);
    let navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [showAlert, setShowAlert] = useState(false)

    const [resetPasswordData, setResetPasswordData] = useState({
        token: searchParams.get("token"),
        password: "",
    });

    const {
        register,
        formState: { errors },
        getValues,
        handleSubmit
    } = useForm({
        mode: 'onBlur',
        criteriaMode: 'firstError',
        shouldFocusError: true,
        shouldUnregister: true
    });

    useEffect(() => {
        document.title = 'Контакт–Центр м. Запоріжжя 1580';
        window.scrollTo(0, 0);

        if (resetPasswordData.token === '' || resetPasswordData.token === null) {
            navigate("/");
        }

        const checkToken = async () => {

            try {
                let response = await fetch(url_ch, {
                    method: "POST",
                    body: JSON.stringify(resetPasswordData),
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-XSRF-TOKEN': cookies['XSRF-TOKEN']
                    }
                });
                const data = await response.json();
                if (response.status === 200) {
                    if (data.message !== 'ok') {
                        navigate("/");
                    }

                } else {
                    navigate("/");
                }
            } catch (err) {
                navigate("/");
            }
        }

        checkToken()

    }, []);

    const handlechpassword = (e) => {
        const password = e.target.value;
        setResetPasswordData(previousState => {
            return { ...previousState, password: password }
        });
    };

    const handlesetnewpassword = async () => {

        try {
            let response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(resetPasswordData),
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': cookies['XSRF-TOKEN']
                },
            });

            const data = await response.json();

            if (response.status === 200) {
                if (data.message === 'ok') {
                    scroller.scrollTo('bbb', {
                        duration: 10,
                        delay: 0,
                        smooth: true,
                    })
                    setShowAlert(true)
                }

            } else {
                alert("Сталася помилка")
            }
        } catch (err) {
            alert("Сталася помилка")
        }
    }

    return (
        <>  <Container>
            {/* <Page /> */}
            <Pagetitle title={'Встановлення нового паролю'} />

            <Card className='shadow-sm p-1 mb-5 bg-body rounded'>
                <Card.Body>
                    <Card.Text>
                        {!showAlert ? (
                            <>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <h3>Введіть новий пароль</h3>
                                        (Мінімум 6 символів, максимум 25)
                                    </div>
                                </div>
                                <form>
                                    <div className="row mb-4">
                                        <div className="col-sm-12 col-lg-6 mb-1">
                                            <label htmlFor="password" className="label">Пароль</label>
                                            <div className="pass-wrapper">
                                                <input type={passwordShown ? "text" : "password"}
                                                    {...register("password", {
                                                        required: "Обов’язкове поле!",
                                                        validate: {
                                                            min6: (value) => value.length > 5,
                                                            max10: (value) => value.length < 26
                                                        }
                                                    })}
                                                    className="form-control" id="password" onChange={handlechpassword}
                                                    aria-describedby="password" />
                                                <i onClick={() => setPasswordShown(passwordShown ? false : true)}><BiShow /></i>{" "}
                                            </div>

                                            {errors.password && errors.password.type === "min6" && (
                                                <p className="labal-alert"><div>Поле повинно бути від 6 символів</div></p>
                                            )}
                                            {errors.password && errors.password.type === "max10" && (
                                                <p className="labal-alert"><div>Поле повинно бути до 25 символів</div></p>
                                            )}

                                            {errors.password && (
                                                <p className="labal-alert">
                                                    <div>{errors.password.message}</div>
                                                </p>
                                            )}

                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-sm-12 col-lg-6 mb-1">
                                            <label for="confirmPassword" className="label">Пароль ще раз</label>
                                            <div className="pass-wrapper">
                                                <input type={passwordShown2 ? "text" : "password"}
                                                    {...register("passwordConfirmation", {
                                                        required: "Обов’язкове поле!",
                                                        validate: {
                                                            matchesPreviousPassword: (value) => {
                                                                const { password } = getValues();
                                                                return password === value || "Паролі не співпадають!";
                                                            }
                                                        }
                                                    })}
                                                    className="form-control" id="confirmPassword" aria-describedby="confirmPassword" />
                                                <i onClick={() => setPasswordShown2(passwordShown2 ? false : true)}><BiShow /></i>{" "}
                                            </div>
                                            {errors.passwordConfirmation && (
                                                <p className="labal-alert"><div>{errors.passwordConfirmation.message}</div>
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-primary" onClick={handleSubmit(handlesetnewpassword)} >Встановити</button>
                                </form>
                            </>
                        ) : (
                            <>
                                <div className="d-flex justify-content-center row" id="bbb">
                                    <Row>
                                        <div className="col">
                                            <span className="rememberPaswordResult"><MdOutlineDone /></span>
                                        </div></Row>
                                    <Row>  <div className="col rememberPaswordResult2 pt-3">
                                        <b> Новий пароль встановленно успішно.</b>
                                        <div className='py-3'>
                                            Зараз ви можете прейти до особистого кабінету
                                        </div>
                                    </div>
                                    </Row>
                                    <Row className="py-1">
                                        <Col className="d-flex justify-content-center">
                                            <Button variant="primary" onClick={() => { navigate("/") }}>Ok

                                            </Button>{''}
                                        </Col></Row>
                                </div>
                            </>
                        )}

                    </Card.Text>
                </Card.Body>
            </Card>

        </Container >
        </>
    )
}

export default ResetPasswordConfirm