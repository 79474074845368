import React, { useState, useEffect, useCallback, Suspense } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { ThreeDots } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { IoReloadOutline } from "react-icons/io5";
import { scroller } from 'react-scroll';
import NewsService from '../../services/newsService';
import Pagetitle from '../Pagetitle';

const Page = React.lazy(() => import('../page'));

function News() {
  let navigate = useNavigate();
  const [showspiner, setShowspiner] = useState(false);
  const [last, setLast] = useState(true);
  const [news, setNews] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    document.title = 'Новини – Контакт–Центр м. Запоріжжя 1580';
  }, []);

  const fetchNews = useCallback(async () => {
    try {
      setShowspiner(true);
      const response = await NewsService.getnews(pageCount);
      if (pageCount === 0) {
        scroller.scrollTo('top', {
          duration: 0,
          delay: 0,
          smooth: false,
        });
      } else {
        scroller.scrollTo('bl_s', {
          duration: 300,
          delay: 0,
          smooth: false,
          offset: -10,
        });
      }
      setNews((prevNews) => {
        const newNews = response.data.content.filter(
          (newItem) => !prevNews.some((existingItem) => existingItem.id === newItem.id)
        );
        return [...prevNews, ...newNews];
      });
      setLast((response.data.page.number) === (response.data.page.totalPages-1))
    } catch (error) {
      console.error(error);
    } finally {
      setShowspiner(false);
    }
  }, [pageCount]);

  useEffect(() => {
    fetchNews();
  }, [fetchNews]);

  const handleNews = (id) => {
    navigate("/news/newsitem/" + id);
  };

  const monthNames = [
    'січня', 'лютого', 'березня', 'квітня', 'травня', 'червня',
    'липня', 'серпня', 'вересня', 'жовтня', 'листопада', 'грудня'
  ];
  
  function getMonthName(monthNumber) {
    return monthNames[monthNumber - 1] || ''; // Возвращает название или пустую строку, если число некорректно
  }

  const ImageWithPlaceholder = ({ d }) => {
    const [isLoading, setIsLoading] = useState(true);
    return (
      <Col key={d.id} md={6} sm={8} lg={6} xl={3} xxl={3} className='p-1 cursorp d-flex justify-content-center'>
        <Card id='news--card' className='news-block' onClick={() => handleNews(d.id)}>
          <Card.Body className='news--body' id={`news-${d.id}`}>
            {/* <div
              className={`spinner ${isLoading ? 'visible' : 'hidden'}`}
              // style={{ height: '200px' }}
            ></div> */}

<div class="spinner-container" className={`spinner-container ${isLoading ? 'visible' : 'hidden'}`}>
  <div class="spinner"></div>
</div>
            {d.header_IMG_ID ? (
              <Image
                className='news-img'
                alt={d.title_UA || 'Новина'}
                src={`/api/files/${d.header_IMG_ID}`}
                onError={(e) => { e.target.onerror = null; e.target.src = '/no-image-icon.png'; }}
                onLoad={() => setIsLoading(false)}
                style={{ display: isLoading ? 'none' : 'block' }}
              />
            ) : (
              <Image
                className='news-img'
                alt='No image available'
                src='/no-image-icon.png'
              />
            )}

            <div className='p-3'>
              <div className="news__time">
                {d.create_DATE.split('.')[0]+' '+getMonthName(d.create_DATE.split('.')[1])+' '+d.create_DATE.split('.')[2]}
              </div>
              <div className="news__title">
                {d.title_UA}
                <span className="news__info">
                  {d.description_UA}
                </span>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  return (
    <>
      <Container>
        <Suspense fallback={<div>Loading...</div>}>
          <Page />
        </Suspense>
        <Pagetitle title={'Новини'} />

        <Row className="d-flex justify-content-center">
          {news && news.map((d) => <ImageWithPlaceholder key={d.id} d={d} />)}
          {!showspiner && !last && (
            <div className="text-center d-grid d-flex justify-content-center">
              <div
                className="p-1 more_news_hover"
                onClick={() => {
                  if (!showspiner) {
                    setPageCount((prevPageCount) => prevPageCount + 1);
                  }
                }}
              >
                <div className='more_news'>
                  <IoReloadOutline size={50} />
                </div>
                <div>Завантажити більше</div>
              </div>
            </div>
          )}
          {showspiner && (
            <div id='bl_s' className='d-grid d-flex justify-content-center'
            style={{padding: pageCount===0 ? '200px' : '0',}}
            >
              <ThreeDots
                height="40"
                width="120"
                radius="7"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
        </Row>
      </Container>
    </>
  );
}

export default News;
