import React, { useEffect } from 'react'
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import HeaderComponet from './components/header/Header';
import FooterComponet from './components/footer/Footer';
import News from './components/news/News';
import Main from './components/MainPage';
import ProPortal from './components/proportal/ProPortal';
import TechSupport from "./components/techsupport/techSupport";
import Remontworks from './components/remontworks/remontworks';
import UserProfile from './components/profile/userprofile';
import Requests from './components/requests/requests';
import Result from './components/result/result';
//import Offer from './components/offer/offers';
import AxiosInterceptorsSetup from './components/AxiosInterceptorsSetup';
import ResetPasswordConfirm from "./components/ResetPasswordConfirm";
import RegistrationConfirm from "./components/RegistrationConfirm"
import NotFound from './components/404';
import CreaterequestUser from './components/profile/createrequest'
import { BrowserRouter } from "react-router-dom";
import NewsItem from "./components/news/newsItem"
import PrivacyPolicy from './PrivacyPolicy';
import ScrollTop from './tools/ScrollTop'  

import ReactGA from 'react-ga4';
ReactGA.initialize("G-WL3NHLY9NW");


function App() {

  function AxiosInterceptorNavigate() {

    let navigate = useNavigate();

    useEffect(() => {
      AxiosInterceptorsSetup(navigate);
    }, [navigate])

    return <></>;
  }

  return (
    <>  
      <BrowserRouter>
      <ScrollTop>
      <HeaderComponet />
        {<AxiosInterceptorNavigate />}
    
      
        <div className="content">
          <Routes>
            <Route exact={true} path='/' element={<Main />} />
            <Route exact={true} path='/privacypolicy' element={<PrivacyPolicy />} />
            <Route exact={true} path='/news' element={<News />}></Route>
            <Route exact={true} path='/techsupport' element={<TechSupport />}></Route>
            <Route path='/news/newsitem/:id' element={<NewsItem/>}></Route>
            <Route exact={true} path='/proportal' element={<ProPortal />}></Route>
            <Route exact={true} path='/remontworks' element={<Remontworks />}></Route>
            <Route exact={true} path='/profile' element={<UserProfile />}></Route>
            <Route exact={true} path='/requests' element={<Requests />}></Route>
             <Route exact={true} path='/result' element={<Result />}></Route> 
            {/* <Route exact={true} path='/offer' element={<Offer />}></Route> */}
            <Route exact={true} path='/resetpassword' element={<ResetPasswordConfirm />}></Route>
            <Route exact={true} path='/registrationConfirm' element={<RegistrationConfirm />}></Route>
            <Route path='/profile/createrequest' element={<CreaterequestUser />}></Route>

            <Route exact={true} path='/404' element={<NotFound />}></Route>
            <Route
              path="*"
              element={<Navigate to="/404" replace />}
            />

          </Routes>
        </div>
        
  
        <FooterComponet />
        </ScrollTop>
      </BrowserRouter>

    </>
  );
}

export default App;
