import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'leaflet/dist/leaflet.css'
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import axios from 'axios';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux'
import store from './components/redux/store'
import {
  GoogleReCaptchaProvider
} from "react-google-recaptcha-v3";


axios.interceptors.request.use(
  request => {

    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.accessToken && (request.url.includes('user') || request.url.includes('checkUser'))) {

      request.headers['Authorization'] = 'Bearer ' + user.accessToken;

    }

    return request;
  },
  error => {
    return Promise.reject(error);
  }
);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <CookiesProvider>
    <Provider store={store}>
      <GoogleReCaptchaProvider reCaptchaKey="6LfiC1ghAAAAAIh6aNPHwnscnMWbBzNYc8FPW8n4"
        language="uk-UA">
        <App />
      </GoogleReCaptchaProvider>
    </Provider>
  </CookiesProvider>
  //  </React.StrictMode>
);

