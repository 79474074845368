import Mainpagebaner from './mainpage/Mainpagebaner';
import Bloks from './mainpage/bloks';
import Whattoreport from './mainpage/whattoreport';
import Way from './mainpage/way';
import Howwork from './mainpage/howwork';
import React, { useEffect } from "react";
import NewsBlock from "./mainpage/newsBloks";

function MainPage() {

    useEffect(() => {
        document.title = 'Контакт–Центр м. Запоріжжя 1580';
    }, []);

    return (
        <>
            {/* <alarmBloks.alarm1 /> */}
            <Mainpagebaner/>

            <Bloks/>

            <Whattoreport/>

            <Way/>

            <Howwork/>
            {/* <NewsBlock /> */}
        </>

    );
}

export default MainPage;