import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';


function Howwork() {

  return (
    <>
      <Container className='py-5'>


        <section className='text-center py-5' name='what-notify'><h2>Як працює контакт-центр?</h2>
        </section>
        <Row>
          <Col md={12} lg={6} xl={6} xxl={6}>
            <Row >
              <Col sm={6} className='ps-4'>

                <Card.Title className='py-3 xxx3'>1. Приймаємо звернення</Card.Title >
                <div>
                  <ul class="px-0 list-unstyled">
                    <li className='py-2'>  -через онлайн-портал:<br />
                      <span className='title--howwork'>1580.zp.gov.ua</span></li>
                    <li className='py-2'>- за телефонами:</li>
                    <li className='title--howwork'>15-80 (зі стаціонарних)</li>
                    <li className='title--howwork'>(061) 787 15 80</li>
                    <li className='title--howwork'>(050) 414 15 80</li>
                    <li className='title--howwork'>(067) 656 15 80</li>
                  </ul>
                </div>

              </Col>

              <Col sm={6} className="pt-3 text-center">
                <img className="img-fluid img-hover" height="280px" width="283px" src="/pic1.svg" alt="Онлайн портал 1580"></img>
              </Col>
            </Row>
          </Col>

          <Col md={12} lg={6} xl={6} xxl={6}>
            <Row>
              <Col sm={6} className='ps-4'>

                <Card.Title className='py-3 xxx3'> 2. Передаємо виконавцям</Card.Title >
                <div>
                  Оператори Контакт-центру опрацьовують звернення та скеровують їх профільним службам
                  на виконання
                </div>

              </Col>
              <Col sm={6} className="pt-3 text-center">
                <img className="img-fluid img-hover" height="280px" width="283px" src="/pic2.svg" alt="Онлайн портал 1580"></img>
              </Col>
            </Row>
          </Col>


        </Row>
        <Row>

          <Col md={12} lg={6} xl={6} xxl={6}>
            <Row>
              <Col sm={6} className='ps-4'>


                <Card.Title className='py-3 xxx3'>3. Повідомляємо про результат</Card.Title>


                Після проведення робіт повідомимо вам результат і запитаємо вашу оцінку


              </Col>
              <Col sm={6} className="pt-3 text-center">
                <img className="img-fluid img-hover" height="280px" width="283px" src="/pic3.svg" alt="Онлайн портал 1580"></img>
              </Col>
            </Row>
          </Col>

          <Col md={12} lg={6} xl={6} xxl={6}>
            <Row>
              <Col sm={6} className='ps-4'>


                <Card.Title className='py-3 xxx3'>4. Консультуємо та інформуємо</Card.Title>

                Надаємо консультаціїї  та повідомляємо про планові аварійні роботи комунальних служб у місті Запоріжжі


              </Col>
              <Col sm={6} className="pt-3 text-center">
                <img className="img-fluid img-hover" height="280px" width="283px" src="/pic4.svg" alt="Онлайн портал 1580"></img>
              </Col>
            </Row>
          </Col>


        </Row>
      </Container>

    </>

  );
}

export default Howwork;