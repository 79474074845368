// export const changeUserName = (name) => {
//     return {
//      type: "CHANGE_USERNAME",
//      payload: name
//     }
//    }


import { createSlice } from '@reduxjs/toolkit'

const setStatus = createSlice({
  name: 'status',
  initialState: '',
  reducers: {
    addStatus(state, action) {

    //   state.push({
    //     status: action.payload
    //   })

    return {
        ...state,
        value: action.payload,
      }
    }
  }
})

export const { addStatus } = setStatus.actions
export default setStatus.reducer