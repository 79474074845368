import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// ==============================|| NAVIGATION - SCROLL TO TOP ||============================== //

const ScrollTop = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;

  // useEffect(() => {

  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: 'smooth'
  //   });

  // }, [pathname]);

  useEffect(() => {
    const scroll = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    };
  
    requestAnimationFrame(scroll);
  }, [pathname]);

  return children || null;
};

export default ScrollTop;
