import Container from 'react-bootstrap/Container';
import Page from '../page';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import React, { useState, useEffect } from 'react';
import makeAnimated from 'react-select/animated';
import axios from "axios";
import ScrollToTop from "react-scroll-to-top";
import { ThreeDots } from 'react-loader-spinner'
import { IoMdAlert } from "react-icons/io";
import InfiniteScroll from "react-infinite-scroll-component";
import Pagetitle from '../Pagetitle';
import { IoReloadCircleSharp, IoCloseCircleSharp } from "react-icons/io5";
import ReadMore from '../../tools/readMore';
import { LuCalendarClock } from "react-icons/lu";

function Remontworks() {


    const animatedComponents = makeAnimated();
    const [selectedValue, setSelectedValue] = useState(null);
    const [showsblank, setshowsblank] = useState(false);
    const [showspiner, setShowspiner] = useState(false);
    const [itemOffset, setItemOffset] = useState(0);
    const [news, setNews] = useState([]);
    const [hasMore, sethasMore] = useState(false);
    const [needupdate, setNeedupdate] = useState(false);

    const fetchMoreData = () => {

        if (!hasMore) {
            setShowspiner(true)
        }

        let label = null;
        let value = null;

        if (selectedValue != null) {
            label = selectedValue.label;
            value = selectedValue.value;
        }

        axios.post("/api/repairworks/list", {
            label: label,
            value: value,
            page: itemOffset
        }

        ).then((response) => {
            setshowsblank(response.data.content.length===0)
            if (itemOffset === 0) {
                setNews([])
            }
            setNews((news) => [...news, ...response.data.content]);
            setItemOffset(response.data.page.number + 1)

            const isLast = response.data.page.number === response.data.page.totalPages - 1;

            sethasMore(!isLast)

            if (response.data.content.length===0){
                sethasMore(false)
            }else{
                sethasMore(!isLast)
            }
            setShowspiner(false)
        }).catch(error => {
            setShowspiner(false)
        })
    }

    const handleChange = value => {
        console.log(value)
      //  setNews([])
        setShowspiner(false)
        setItemOffset(0)
        sethasMore(true)
        setshowsblank(false)
        setSelectedValue(value);
    }

    useEffect(() => {
        fetchMoreData()
    }, [selectedValue, needupdate]);


    const update = () => {
        setItemOffset(0)
        setshowsblank(false)
        sethasMore(true)
        setNews([])
        setNeedupdate(!needupdate)
    }


    useEffect(() => {
        document.title = 'Ремонтні роботи – Контакт–Центр м. Запоріжжя 1580';
        window.scrollTo(0, 0);
    }, []);


    const loadOptions = async (str) => {
        if (str.length >= 3) {
            const response = await axios.post("/api/repairworks/streetlist",
                { str: str });
            return response.data;
         }
    };

    const ClearIndicator = props => {
        return (
            <components.ClearIndicator {...props}>
                <IoCloseCircleSharp className='cp' size={30} color='#a1d2b8' />
            </components.ClearIndicator>
        );
    };

    const sortedItems = (items) => [...items].sort((a, b) => {

        const numA = parseInt(a, 10);
        const numB = parseInt(b, 10);

        if (!isNaN(numA) && !isNaN(numB)) {
            return numA - numB;
        }

        if (isNaN(numA) && isNaN(numB)) {
            return a.localeCompare(b);
        }
        return isNaN(numA) ? 1 : -1;
    });

    return (
        <>
            <ScrollToTop smooth color="#028B42" className='m2'
            />
            <Container>
                <Page />

                <Pagetitle title={'Ремонтні роботи'} />
                <Col className="pt-md-0 pt-1 ps-2 pe-2" style={{fontSize: '0.968rem', fontWeight : '500', textAlign: 'justify'}}>
                         <p>
                              
                                Телеграм-бот «Запоріжжя 1580» від контакт-центру 15-80 інформує про ремонтні роботи за вашою адресою. Перейти до нього можна за <a href="https://t.me/colcentrebot" target="_blank" rel="noreferrer">посиланням</a> або з особистого кабінету на нашому сайті.
                              
                               </p>
                               </Col>

                <Row className='mb-4 mt-4'>
                    <div class="col-10">
                        <AsyncSelect classNamePrefix="mySelect"

                            defaultOptions
                            cacheOptions

                            // components={{ DropdownIndicator, animatedComponents }}
                            components={{ DropdownIndicator: null, ClearIndicator, animatedComponents }}
                            loadOptions={(str) => loadOptions(str)}

                            onChange={(value) => handleChange(value)}
                            value={selectedValue}
                            closeMenuOnSelect
                            loadingMessage={() => 'Шукаю, зачекайте...'}

                            placeholder={'Пошук за адресою'}
                            noOptionsMessage={() => 'Введіть мініум 3 літери або уточніть пошук'}
                            isClearable

                            getOptionLabel={(option) => `${option.labeltype} ${option.label}`}

                            styles={{
                                option: (styles, state) => ({ // fixed
                                    ...styles,
                                    fontSize: state.isSelected ? 21 : 19,
                                    color: state.isSelected ? 'black' : '#444444'
                                }),
                            }}

                            theme={(theme) => ({
                                ...theme,

                                colors: {
                                    ...theme.colors,
                                    primary25: '#E1E4E8',
                                    primary: '#c2e6d3',
                                },
                            })}

                        />


                    </div>
                    <div class="col-2"><IoReloadCircleSharp onClick={() => update()} className='cp' size={47} color='#a1d2b8' /> </div>
                </Row>

                <div>
                    <InfiniteScroll
                        dataLength={itemOffset}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={
                            <div className='d-grid d-flex justify-content-center'>
                                <ThreeDots
                                    height="130"
                                    width="130"
                                    radius="9"
                                    color="#4fa94d"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div>}

                    >

                        {news && news.map((d, index) => (
                            <div key={index}>
                                <div className='row'>
                                    <Col lg={10} className="p-1">
                                        <Card className=' shadow-sm bg-body rounded repeir-card' >

                                            <div class="card-header">
                                                <div class="d-flex align-items-center">
                                                    <div class="remontworks-icon me-3" style={{ backgroundImage: `url( ${'/repeirworks/' + d.problem_id + '.png'} )` }}>
                                                        {d.is_emergency != null &&
                                                            <div className='repeirs-alarm'><IoMdAlert /></div>
                                                        }
                                                    </div>

                                                    <div className='me-auto pt-2'>
                                                        <h5 style={{ letterSpacing: '0.04em', fontSize: '1.1rem', marginTop: '4px' }} className='fw-bold'>{d.problem.replace(/\./g, '')}</h5>
                                                        <h6 class="mt-2 m2">{d.reason.replace(/\./g, '')}</h6>
                                                    </div>

                                                </div>
                                                <div class="col">
                                                    <h6 class="m1 pt-2">{d.reason.replace(/\./g, '')}</h6>
                                                </div>
                                            </div>
                                            <Card.Body>

                                                <div class="col d-flex align-items-start me-auto" style={{ fontSize: '0.94rem' }}>

                                                    <div>
                                                        {
                                                            d.addresses.split(';').map(

                                                                ad => <div className='py-1'>
                                                                    <span style={{ fontSize: '1.16rem' }}>

                                                                        {ad.split(':')[0] + ': '}

                                                                    </span>

                                                                    {sortedItems(ad.split(':')[1].split(',')).map((item, index, array) => (
                                                                        <span key={index}>
                                                                            {item}{index !== array.length - 1 && ', '}
                                                                        </span>
                                                                    ))}
                                                                </div>

                                                            )

                                                        }

                                                    </div>
                                                </div>


                                            </Card.Body>

                                            <div class="card-footer text-muted" style={{ fontSize: '0.94rem' }}>



                                                <div className='m1'>
                                                    <div class="mt-0 col"><LuCalendarClock size={23} /><b> Дата початку:</b></div>
                                                    <div className='p-1'>{d.date_beg}</div>

                                                    <div class="mt-2 col"><LuCalendarClock size={23} /><b> Планова дата закінчення:</b></div>
                                                    <div className='p-1'>{d.date_plan}</div>
                                                </div>

                                                <div class="mt-2 col"><b>Примітка: </b></div>
                                                <div className='p-1'><ReadMore style={{ fontSize: '0.94rem' }} text={d.event_text} /></div>


                                                <div class="mt-2 col"><b>Виконавець:</b></div>
                                                <div className='p-1'>{d.executor}</div>

                                            </div>

                                        </Card>
                                    </Col>

                                    <Col lg={2} className="m2 p-1">
                                        <Card className='h-100 shadow-sm bg-body rounded repeir-card'>
                                            <Card.Body>

                                                <Card.Text>

                                                    <div class="card-img-overlay d-flex flex-column" style={{ fontSize: '0.94rem' }}>
                                                        <div><LuCalendarClock size={23} /><span class="fw-bold"> Дата початку:</span>
                                                            <div className="nowrap p-1">{d.date_beg}</div>
                                                            {/* <div>{d.date_beg.split(' ')[1]}</div>*/}
                                                        </div>

                                                        <div class="mt-auto"><LuCalendarClock size={23} /><span class="fw-bold" style={{ fontSize: '0.94rem' }}> Планова дата закінчення:</span>
                                                            <div className="nowrap p-1">{d.date_plan}</div>
                                                            {/* <div>{d.date_plan.split(' ')[1]}</div>*/}


                                                        </div>
                                                    </div>

                                                </Card.Text>



                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </div>
                            </div>
                        ))}

                    </InfiniteScroll>
                </div>

                {!hasMore && showspiner &&
                    <div className='d-grid d-flex justify-content-center p-5'>
                        <ThreeDots
                            height="130"
                            width="130"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                }
                {showsblank &&
                    <>
                        <div class="notfound py-5">

                            <section class="text-center"><span className='rep2-icon'><IoMdAlert /></span></section>
                            <section class="text-center py-1"><span className='fw-normal'>Ремонтних робіт не знайдено</span></section>

                        </div>
                    </>
                }

            </Container>

        </>

    );
}

export default Remontworks;