import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



const CloseSesionModal = ({ show, onHide: closeModal }) => {


  return (

    <Modal show={show} onHide={closeModal} centered size='sm'>
  
      <Modal.Body>
        <Row className="py-1">
          <Col className="text-center">Термін дії аутентифікації минув !
          </Col>
          </Row>
         
          <Row className="py-3">
          <Col className="text-center">
            <Button className='pe-5 ps-5' variant="primary" onClick={closeModal}>
          Ok
        </Button>
          </Col>
          
          </Row>
      </Modal.Body>
     
    </Modal>
  );
}

export default CloseSesionModal;