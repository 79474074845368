import Container from 'react-bootstrap/Container';
import { useSelector } from "react-redux"
import React, { useEffect, useState, useRef } from "react";
import AuthService from "../../services/AuthService";
import { useNavigate } from 'react-router-dom';
import { RiCloseCircleLine } from "react-icons/ri";
import { FiAlertCircle } from "react-icons/fi";
import { TbStepInto } from "react-icons/tb";
import Navbar from 'react-bootstrap/Navbar';
import axios from "axios";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Dropzone, FileMosaic } from "@files-ui/react";
import AsyncSelect from 'react-select/async';
import { useForm } from "react-hook-form";
import Button from 'react-bootstrap/Button';
//import Spinner from "react-bootstrap/Spinner";
import { scroller } from 'react-scroll'
import { ThreeDots } from 'react-loader-spinner'
import { Alert } from "react-bootstrap";
import Createrequest_confirm_modal from './createrequest_confirm_modal';
import Select, { } from 'react-select';
import House_letter_data from '../../letter_house.json';
import { BiWorld } from "react-icons/bi";

function Createrequest() {

    const options = House_letter_data;
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const ref = useRef([]);

    const {
        handleSubmit
    } = useForm({
        mode: 'onChange',
        criteriaMode: 'firstError',
        shouldFocusError: true,
        shouldUnregister: true
    });

    const handleClose = () => {
        navigate("/profile");
    }

    let navigate = useNavigate();

    const [showConfirm, setshowConfirm] = useState(false);
   // const [spiner, setSpiner] = useState(false);
    const [showspiner1, setShowspiner1] = useState(false);
    const [showstreeterror, setshowstreeterror] = useState(false);
    const [showstreeterror2, setshowstreeterror2] = useState(false);
    const [showsRnerror, setshowRnerror] = useState(false);
    const [showhouseerror, setShowhouseerror] = useState(false);
    const [message, setMessage] = useState('');

    const [problem, setProblem] = useState(null);
    const [problem2, setProblem2] = useState([]);
    const [problem3, setProblem3] = useState([]);

    const status = useSelector(state => state.status).value

    const [files, setFiles] = useState([]);

    const [active, setActive] = useState(null)
    const [active2, setActive2] = useState(null)
    const [active3, setActive3] = useState(null)

    const [show_step2, setShow_step2] = useState(false)
    const [show_step3, setShow_step3] = useState(false)
    const [show_step4, setShow_step4] = useState(false)

    const [show_block2, setShow_block2] = useState(true)

    const [myAddress, setmyAddress] = useState(true)
    const [otherAdress, setotherAdress] = useState(false)

    const [commentText, setCommentText] = useState('')
    const [getPublic, setGetPublic] = useState(false)

    const [selectedStreet, setSelectedStreet] = useState(null);

    const [selectedRn, setSelectedRn] = useState(null);
    const [rn_list, setRn_list] = useState(null);

    const [house, setHouse] = useState('');
    const [corpus, setCorpus] = useState('');
    const [house_p, setHouse_p] = useState(null);
    const [flat, setFlat] = useState('');

    const chTypeAddressOt = () => {
        if (!otherAdress) {
            setmyAddress(false)
            setotherAdress(true)
        }

    }

    const chTypeAddressMy = () => {
        if (!myAddress) {
            setmyAddress(true)
            setotherAdress(false)
        }

    }

    const [totalSize, setTotalSize] = useState(0); // Tracks total size in bytes
    const maxTotalSize = 10 * 1024 * 1024;
 
    //  Обработчик добавления файлов
    const updateFiles = (incomingFiles) => {

        const validFiles = incomingFiles.filter(file => !file.errors || file.errors.length === 0);

        if (validFiles.length >=4){
            alert("Ви завантажили максимальну кількість файлів!");
            return;
        }

        // Считаем общий размер загружаемых файлов
        const incomingSize = validFiles.reduce((acc, item) => acc + item.file.size, 0);

        if (incomingSize <= maxTotalSize) {
            //setFiles([...files, ...incomingFiles]);
            setFiles(incomingFiles);
            setTotalSize(incomingSize);
        } else {
            alert("Перевищено ліміт за розміром файлів!");
            return;
        }
    };


    const removeFile = (id) => {

        const file = files.find(item => item.id === id);

        setFiles(files.filter((x) => x.id !== id));

        if (!file.errors || file.errors.length === 0) {
            setTotalSize(totalSize - file.file.size);
        }

    };



    useEffect(() => {
        // alert(status)
        //  window.scrollTo(0, 0);
        document.title = 'Створення звернення – Контакт–Центр м. Запоріжжя 1580';
        const user = AuthService.getCurrentUser();

        if (user) {
            // AuthService.profile().then((response) => {
            //     setUserFrofile(response.data)
            // }).catch(error => {

            // })
            setShowspiner1(true)

            if (status === false) {

                axios.post("/api/general/getProblem_main", {}

                ).then((response) => {

                    setShowspiner1(false)
                    setProblem(response.data)

                }).catch(error => {
                    setShowspiner1(false)
                })

            } else {
                navigate("/profile");
            }

        } else {
            navigate("/");
        }

    }, []);


    const onSubmit = () => {

        if (selectedStreet === null && !myAddress) {

            setshowstreeterror(true)

            return
        }


        if (selectedRn == null && !myAddress) {

            setshowRnerror(true)
            return
        }

        if (!myAddress && house === '') {

            setMessage("Оберіть будинок!")
            setShowhouseerror(true)

            return
        }

        setshowConfirm(true)
    }


    const handle_step1 = (d) => {
        setShow_block2(true)
        ref.current = []

        setShow_step3(false)
        setShow_step4(false)

        setActive2(null)
        setActive(d)

        axios.post("/api/general/getWraperProblem/" + d.id, {}

        ).then((response) => {

            if (response.data.length === 0) {
                setShow_block2(false)

                setProblem2([])

                axios.post("/api/general/getProblem/" + d.id, {}

                ).then((response) => {

                    setProblem3(response.data)

                }).catch(error => {

                })

                setShow_step3(true)
            } else {
                setProblem2(response.data)
                setShow_step2(true)
            }



            scroller.scrollTo('step2', {
                duration: 300,
                delay: 0,
                smooth: true,
                offset: -10
            })

        }).catch(error => {

        })
    }

    const handle_step2 = (d) => {

        axios.post("/api/general/getProblem/" + d.id, {}

        ).then((response) => {

            setProblem3(response.data)

            scroller.scrollTo('step3', {
                duration: 300,
                delay: 0,
                smooth: true,
                offset: -10
            })

        }).catch(error => {

        })

        setShow_step3(true)
        setShow_step4(false)
        setActive2(d)

    }

    const handleHouse = (house) => {

        let isnum = /^\d+$/.test(house);

        if (isnum || house === '') {

            if (house.length > 3) {
                return
            }

            setShowhouseerror(false)
            setHouse(house)
        } else {
            return
        }
    }

    const handleCorpus = (corpus) => {

        let isnum = /^\d+$/.test(corpus);

        if (isnum || corpus === '') {

            if (corpus.length > 3) {
                return
            }

            setCorpus(corpus)
        } else {
            return
        }
    }

    const handleFlat = (flat) => {
        setFlat(flat)
        // let isnum = /^\d+$/.test(flat);

        // if (isnum || flat === '') {
        //     setFlat(flat)
        // } else {
        //     return
        // }

    }

    const handle_step3 = (d, index) => {

        // console.log(index)
        //  console.log(ref.current)

        for (let i = 0; i < ref.current.length; i++) {
            if (ref.current[i] != null) {
                ref.current[i].checked = false;
            }

        }


        ref.current[index].checked = true;

        setActive3(d)
        setShow_step4(true)
        scroller.scrollTo('step4', {
            duration: 300,
            delay: 0,
            smooth: true,
            offset: -10
        })
    }

    const loadOptions = async (str) => {
        if (str.length >= 3) {
            const response = await axios.post("/api/repairworks/streetlist",
                { str: str });
            return response.data;
        }
    };

    // consxt loadOptionsRn = async (str) => {
    //     if (str.length >= 3) {
    //         const response = await axios.post("/api/repairworks/streetlist",
    //             { str: str });
    //         return response.data;
    //     }
    // };


    //подстановка района при віборе улиці
    const handleStreetSelect = (value) => {

        setIsLoading(true)
        setSelectedRn(null)
        setshowRnerror(false)

        if (value === null) {
            setIsLoading(false)
            setRn_list(null)
            setIsDisabled(true)
            return
        }

        axios.post("/api/general/getDistrictsFromStreetId/" + value.value, {}

        ).then((response) => {
            setIsDisabled(false)

            setRn_list(response.data)

            console.log(response.data.length)

            if (response.data.length === 1) {
                setSelectedRn(response.data[0])
            }

            setIsLoading(false)

        }).catch(error => {
            setIsLoading(false)
        })
    }


    return (

        <>

            <Createrequest_confirm_modal
                show={showConfirm}
                onHide={() => { setshowConfirm(false) }}
                problem={active}
                reason={active3}
                comment={commentText}
                files={files}
                gpublic={getPublic}
                myAddress={myAddress}
                selectedStreet={selectedStreet}
                house={house}
                house_p={house_p}
                flat={flat}
                corpus={corpus}
                rn={selectedRn}
            />

            <Container className='pt-2'>
                <Navbar className='cabinet-header'>
                    <Container>
                        <Navbar.Brand><span className='text-white'>Створення звернення</span></Navbar.Brand>
                        <Navbar.Toggle />
                        <Navbar.Collapse className="justify-content-end">
                            <Navbar.Text>
                                <span onClick={handleClose} className='create-request-clouse-icon'><RiCloseCircleLine /></span>
                            </Navbar.Text>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>


                <div className="mb-4 text-center pt-4 pb-1 border-bottom">
                    <h5 className="mb-0 mx-2 creare-request-title">Оберіть проблему</h5><TbStepInto className='creare-request-title-icon' />
                </div>

                <Row>
                    {showspiner1 &&

                        <div className='d-grid d-flex justify-content-center py-5'>
                            <ThreeDots
                                height="160"
                                width="160"
                                radius="10"
                                color="#4fa94d"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
                    }
                    {problem && problem.map(d => (
                        <Col lg={2} className='pb-3'
                            onClick={() => handle_step1(d)}
                            key={d.id}>
                            <div className={`problem-block1_wrap ${active === d && 'activer'}`}>
                                <div className="icon" style={{ backgroundImage: `url( ${'/' + d.id + '.webp'} )` }}>
                                </div>
                                <div className='addproblem-text'>
                                    {d.name}
                                </div>
                            </div>

                        </Col>
                    ))}
                </Row>

                {show_block2 &&
                    <>
                        <div id='step2' className="mb-4 text-center pt-5 pb-1 border-bottom">
                            <h5 className="mb-0 mx-2 creare-request-title">Оберіть блок причин</h5><TbStepInto className='creare-request-title-icon' /></div>

                        {show_step2 &&

                            <div className='pb-4'>

                                {problem2 != null && problem2.map(d => (

                                    <span className={`badge ${active2 === d && 'activer2'}`}
                                        onClick={() => handle_step2(d)}
                                        key={d.id}
                                    >
                                        <div className='text-wrap'>{d.name_ua}</div>
                                    </span>
                                ))}

                            </div>

                        }
                    </>}
                <div id='step3' className="mb-4 text-center pt-1 pb-1 border-bottom">
                    <h5 className="mb-0 mx-2 creare-request-title">Оберіть причину</h5><TbStepInto className='creare-request-title-icon' /></div>

                {show_step3 &&
                    <div className='pb-3'>
                        {problem3 && problem3.map((d, index) => (
                            <div class="col d-flex align-items-center cp" key={d.id} onClick={() => handle_step3(d, index)}>
                                <section class="text-center p-2">
                                    <input
                                        ref={(element) => { ref.current[index] = element }}
                                        className="form-check-input form-check-input_req  me-1 cp" type="checkbox"
                                        id={d.index}></input>
                                </section>

                                <div className='me-auto py-3'>
                                    <label className="form-check-label cp" for={d.index}>{d.name_ua}</label>
                                </div>

                            </div>
                        ))}
                    </div>

                }


                <div id='step4' className="mb-4 text-center pt-3 pb-1 border-bottom">
                    <h5 className="mb-0 mx-2 creare-request-title">Опишіть проблему</h5><TbStepInto className='creare-request-title-icon' /></div>

                {show_step4 &&

                    <div className='pb-5'>
                        <div className="mb-3">


                            <label for="commentText" class="label nowrap">Опис проблеми</label>
                            <textarea className="form-control" id="commentText"
                                rows="3" placeholder='Написати...'
                                value={commentText}
                                onChange={e => { setCommentText(e.target.value); setshowstreeterror2(false) }}
                                maxLength="4000"
                            >
                            </textarea>
                            {showstreeterror2 && (
                                <p className="labal-alert"><div>Опишіть проблему!</div></p>
                            )}
                        </div>


                        <div className="bd-callout bd-callout-info">
                            <div className="pb-2">Завантажте файли</div>

                            <div class="col d-flex align-items-center">
                                <section class="text-center">
                                    <div className="icon-alert">
                                        <FiAlertCircle size={60} />
                                    </div>
                                </section>

                                <div className='me-auto ps-3'>

                                    <div className='upload_alert pb-1'>Приймаються тільки <b>зображення</b> та <b>документы pdf</b>. </div>
                                    <div className='upload_alert py-1'>Максимальний розмір файлу - <b>7 мегабайт</b>, максимальна кількість - <b>3 файла</b>, максимальний розмір завантажених файлів- <b>10 мегабайт</b>.</div>
                                    <div className='upload_alert pt-1'>Увага ! Файли які не пройшли валідацію не завантажуються.</div>

                                </div>
                            </div>


                        </div>
                       

                        <Dropzone
                            onChange={updateFiles}
                            value={files}
                            label='Перетягніть файл, або натисніть сюди'
                            maxFiles={4}
                            maxFileSize={7340032}
                            accept=".PDF,.pdf,image/*"
                            header={false}
                  
                    
                           // footer={false}
                            footerConfig={{
                                customMessage: (
                                  <div style={{ margin: 0, fontSize: '15px' }}>
                                    Розмір завантажених файлів: <b>{(totalSize / 1024 / 1024).toFixed(2)} MB</b>
                                  </div>
                                )
                              }} 

                             
                            //   actionButtons={{ position: "after", uploadButton: {}, abortButton: {} }}

                            //   uploadConfig={{
                            //       url: "/api/user/filesprivate/user",
                            //       method: "POST",
                                
                            //       credentials: 'include',

                            //       headers: {
                             
                            //         'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).accessToken
                            //     },
  
                            //       cleanOnUpload: true,
                            //       autoUpload: true,
                            //    }}
                             
                        >

                            {files.map((file) => (
                                <FileMosaic key={file.id} {...file} onDelete={removeFile} preview />
                            ))}


                        </Dropzone>
                        {/* <div style={{ margin: 0, fontSize: '13px' }}>Розмір завантажених файлів: <b>{(totalSize / 1024 / 1024).toFixed(2)} MB</b></div> */}
             


                        <div class="col d-flex align-items-center py-4">
                            <section class="text-cente pe-2">
                                <input className="form-check-input me-1 cp" type="checkbox" id="getPublic"
                                    onClick={() => setGetPublic(!getPublic)}
                                    checked={getPublic}></input>
                            </section>

                            <div className='me-auto py-2' style={{ marginBottom: '3px' }}>
                                <label className="form-check-label cp" for="getPublic"><BiWorld size={25} />Відображати звернення на сайті</label>
                            </div>

                        </div>
                    </div>
                }

                <div id='step4' className="mb-4 text-center pt-1 pb-1 border-bottom">
                    <h5 className="mb-0 mx-2 creare-request-title">Вкажіть адресу проблеми</h5><TbStepInto className='creare-request-title-icon' /></div>

                {show_step4 &&
                    <div>

                        <div className="form-check">
                            <input className="form-check-input cp" type="radio" name="myAddress"
                                checked={myAddress} id="myAddress" onClick={chTypeAddressMy}></input>
                            <label className="form-check-label cp" for="myAddress">
                                (Моя домашня адреса)
                            </label>
                        </div>

                        <div className="form-check pt-4">
                            <input className="form-check-input cp" type="radio" name="otherAdress" id="otherAdress"
                                checked={otherAdress} onClick={chTypeAddressOt}></input>
                            <label className="form-check-label cp" for="otherAdress">
                                Інша адреса
                            </label>
                        </div>

                        {!myAddress &&
                            <>
                                <div className="row pt-3">

                                    <div className="col-md-6 mb-1">
                                        <label for="street" class="label">Вулиця <span className="labal-alert">*</span></label>
                                        <AsyncSelect id='street' classNamePrefix="mySelect"

                                            isClearable


                                            defaultOptions
                                            cacheOptions
                                            loadOptions={(str) => loadOptions(str)}
                                            onChange={(value) => { setSelectedStreet(value); setshowstreeterror(false); handleStreetSelect(value); }}
                                            value={selectedStreet}
                                            closeMenuOnSelect
                                            loadingMessage={() => 'Шукаю, зачекайте...'}
                                            placeholder={'Оберіть вулицю'}

                                            noOptionsMessage={() => ' Почніть вводити мініум 3 літери або уточніть пошук'}
                                            //    isClearable

                                            getOptionLabel={(option) => `${option.labeltype} ${option.label}`}

                                            styles={{
                                                option: (styles, state) => ({ // fixed
                                                    ...styles,
                                                    fontSize: state.isSelected ? 21 : 19,
                                                    color: state.isSelected ? 'black' : '#444444'
                                                }),
                                            }}

                                            theme={(theme) => ({
                                                ...theme,

                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#E1E4E8',
                                                    primary: '#c2e6d3',
                                                },
                                            })}
                                        />


                                        {showstreeterror && (
                                            <p className="labal-alert"><div>Оберіть вулицю!</div></p>
                                        )}
                                        {/* <Alert className="non-street-user-profile nowrap" show={showstreeterror} variant='danger'>Оберіть вулицю!</Alert> */}
                                    </div>


                                    <div className="col-md-4 mb-1">
                                        <label for="rn" class="label">Район <span className="labal-alert">*</span></label>
                                        <Select id='rn' classNamePrefix="mySelect"

                                            options={rn_list}
                                            onChange={(value) => { setSelectedRn(value); setshowRnerror(false); }}

                                            isSearchable={false}
                                            isLoading={isLoading}

                                            value={selectedRn}
                                            isDisabled={isDisabled}

                                            loadingMessage={() => 'Шукаю, зачекайте...'}
                                            placeholder={''}

                                            noOptionsMessage={() => 'Спочатку оберіть вулицю'}

                                            getOptionLabel={(option) => `${option.district_name}`}
                                            getOptionValue={option => option.id}
                                            styles={{
                                                option: (styles, state) => ({ // fixed
                                                    ...styles,
                                                    fontSize: state.isSelected ? 21 : 19,
                                                    color: state.isSelected ? 'black' : '#444444'
                                                }),
                                            }}
                                            theme={(theme) => ({
                                                ...theme,

                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#E1E4E8',
                                                    primary: '#c2e6d3',
                                                },
                                            })}
                                        />
                                        <Alert className="non-street-user-profile nowrap" show={showsRnerror} variant='danger'>Оберіть район!</Alert>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-6 col-lg-3 col-xxl-2 col-xl-3 col-md-4">
                                        <label for="house" class="label nowrap">Будинок <span className="labal-alert">*</span></label>
                                        <input
                                            type="text" class="form-control" aria-describedby="house" value={house}
                                            onChange={e => handleHouse(e.target.value)} />
                                        <Alert className="non-house-create-request nowrap" show={showhouseerror} variant='danger'>{message}</Alert>
                                    </div>

                                    <div className="col-6 col-lg-3 col-xxl-2 col-xl-3 col-md-4">
                                        <label for="house" class="label">Префікс</label>
                                        <Select classNamePrefix="mySelect"
                                            aria-labelledby="aria-label"
                                            isClearable
                                            onChange={(value) => { setHouse_p(value) }}
                                            placeholder={''}
                                            options={options}
                                            value={house_p}
                                            defaultOptions
                                            closeMenuOnSelect
                                            noOptionsMessage={() => ''}
                                            styles={{
                                                option: (styles, state) => ({ // fixed
                                                    ...styles,
                                                    fontSize: state.isSelected ? 21 : 19,
                                                    color: state.isSelected ? 'black' : '#444444'
                                                }),
                                            }}
                                            theme={(theme) => ({
                                                ...theme,

                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#E1E4E8',
                                                    primary: '#c2e6d3',
                                                },
                                            })}

                                        />
                                    </div>

                                    <div className="col-6 col-lg-3 col-xxl-2 col-xl-3 col-md-4">
                                        <label for="corpus" class="label nowrap">Корпус</label>
                                        <input
                                            type="text" class="form-control" aria-describedby="corpus" value={corpus}
                                            onChange={e => handleCorpus(e.target.value)} />
                                    </div>


                                </div>

                                <Row>

                                    <div className="col-6 col-lg-3 col-xxl-2 col-xl-3 col-md-4">
                                        <label for="flat" class="label nowrap">Квартира</label>
                                        <input
                                            type="text" class="form-control" aria-describedby="flat" value={flat}
                                            onChange={e => handleFlat(e.target.value)}
                                        />

                                    </div>
                                </Row>
                            </>
                        }
                        <Row className="pt-5">
                            <div className="text-center py-1">
                                <label class="label">Поля, позначені <span className="labal-alert">*</span>, є обов’язковими для заповнення</label>
                            </div>
                            <Col className="d-flex justify-content-center pb-5 pt-1">
                                <Button className="btn btn-primary login_modal_button3 py-2" variant="primary" onClick={handleSubmit(onSubmit)} type="submit">
                                  
                                    Подати звернення
                                    
                                </Button>{' '}
                            </Col>
                        </Row>
                    </div>




                }


            </Container>
        </>
    );
}

export default Createrequest;