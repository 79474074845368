import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import React, { useState } from 'react'
import Spinner from "react-bootstrap/Spinner";
import Container from 'react-bootstrap/Container';
import AuthService from "../../../services/AuthService";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import messages from "./messages.json";
import { MdOutlineDone } from "react-icons/md";
import { Card, Col, Row } from 'react-bootstrap';



const IsApplicantsNotClouse = ({ show, onHide: closeModal, onExit, data }) => {
    let navigate = useNavigate();

    const [confirm, setConfirm] = useState(false);
    const [spiner, setSpiner] = useState(false);
    const [success, setSuccess] = useState(false);
    const [alert_text, setAlert_text] = useState(messages.deleteuser_error);
    const [showAlert, setShowAlert] = useState(false);

    const handleSubmit = () => {
        setSpiner(true)
        setConfirm(false)

        const user = AuthService.getCurrentUser();

        if (user) {

            axios.post("/api/auth/loschenuser", {}
            ).then((response) => {
                if (response.status === 200) {
                    if(response.data.message === "ok"){
                       // AuthService.logout();
                       localStorage.removeItem("user");
                        setSuccess(true)
                    } else{
                        setAlert_text(response.data.message)
                        end()
                    }
        
                } else{
                    end()
                }
            }).catch(error => {
                       end()
            })

        } else{
                        setAlert_text(messages.deleteuser_error2)
                        end()
        }


    }


    const nextSteep = () => {
        closeModal()
        navigate("/")

    }

const end = ()=>{
    setShowAlert(true)
    setSpiner(false);
    setConfirm(true);
}

onExit=() => {

if (success){
navigate("/")
}
else{
setConfirm(false);
setSpiner(false);
setShowAlert(false)
}

}

    return (
        <>

            <Modal show={show} onHide={closeModal} onExit={onExit}
                aria-labelledby="isApplicantsNotClouse" fullscreen
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Видалення облікового запису</Modal.Title>
                </Modal.Header>
                {!success &&
                <Modal.Body>
                    <Container>
                    <Alert className="text-center loginFormAlert" show={showAlert} variant='danger'>{alert_text}</Alert>
                    <div className="text-start pb-2" style={{fontSize: '14px'}}>
             

                    Ви можете видалити свій обліковий запис (акаунт) під час чого будуть видалені (знищені) із Системи Ваші облікові дані та відомості (у тому числі дані, які було Вами надано під час реєстрації у Системі). 
Звертаємо увагу, що у такому разі Ви втратите доступ до свого облікового запису (акаунту), не будете отримувати повідомлення від Системи, не зможете отримати витяги із електронної картки звернень, які подавались вами раніше. Доступ до видаленого облікового запису (акаунту)  поновити буде неможливо


                         </div>

                         <div className="text-start" style={{fontSize: '14px'}}>
                         На даний час в автоматизованій системі обліковані наступні ваші звернення, які знаходяться в стані «В роботі»:
                         </div>


                        
                    {data.map(d => (
                        <div key={d.id}>
                        <div className='row'>
                        <Col className="p-1">
<Card className='bg-body rounded repeir-card' >

<div style={{padding: '7px', fontSize: '13px'}}>
<div className='row'>
<span className='nowrap'>від {d.date_beg}</span>

{d.request_number &&
    <>
        <span className='pe-1 ps-2'>№</span><b>{d.request_number}</b></>
}
</div>
<div className='row'>
<span className='pt-2'>
<b>Адреса: </b>
{d.street_name},
<span className='ps-2'>{d.house_num}</span>
</span>
</div>

<div className='row'>
<span className='pt-2'>
<b>Проблема: </b>
{d.problem}
</span>
</div>

<div className='row'>
<span className='pt-2'>
<b>Причина: </b>
{d.reason}
</span>
</div>
<div className='row'>
<span className='pt-2'>
<b>Планова дата виконання : </b>
{d.date_plan}
</span>
</div>
<div className='row'>
<span className='pt-2'>
<b>Виконавець : </b>
{d.executor}
</span>
</div>
      </div>


</Card>
</Col>
</div></div> 
                    ))}
                    
                    <div className="text-start pb-2" style={{fontSize: '14px'}}>
             
                    Якщо Ви дійсно вирішили видалити обліковий запис (акаунт), то Ваші звернення, які перебувають «В роботі» будуть скасовані за вашою ініціативою, а обліковий запис (акаунт) видалено. Для цього - натисніть [Підтверджую]» та [Видалити обліковий запис].
                         </div>

                         

                    </Container>
                </Modal.Body>
                }
                {!success && <>
                <Modal.Footer>
                <div class="col d-flex align-items-center cp" >
                     <section class="text-center p-1">
                                    <input
                                        disabled={spiner}
                                        onClick={() => setConfirm(!confirm)}
                                        className="form-check-input form-check-input_req  me-1 cp" type="checkbox"
                                        id="deleteUser_checkbox">
                                       </input>
                                </section>

                                <div className='me-auto'>
                                    <label className="form-check-label cp" for="deleteUser_checkbox">{messages.deleteuser_confirm_message}</label>
                                </div>
                     </div>
                     <div className="d-flex justify-content-center">
                    <Button id="confirm_deleteuser_b" variant="primary" type="submit" onClick={() => {handleSubmit()}}
                    disabled={!confirm}>
                                    {spiner ? (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                       <>Видалити обліковий запис</>
                                    )}
                                </Button>{' '}         
                    </div>
       
        </Modal.Footer>
        </>}
        {success &&  <Container>
                    <div className="d-flex justify-content-center row pt-3">
                                    <Row>
                                        <div className="col">
                                            <span className="rememberPaswordResult"><MdOutlineDone /></span>
                                        </div></Row>
                                    <div className="col rememberPaswordResult2 pt-3 pb-4">
                                    {messages.deleteuser_success_message}
                                    </div>
                                    
                                    <Row className="py-2">
                                    <Col className="d-flex justify-content-center">
                                        <Button className="login_modal_button3 py-2" variant="primary" type="" onClick={()=> nextSteep()}
                                        >
                                            Закрити форму
                                        </Button>{' '}
                                    </Col>
                                </Row>
                                </div>
 </Container>
}
            </Modal>
        </>
    )
}

export default IsApplicantsNotClouse