// import { createStore } from "redux"


// const initialState = {
//   username: null,
// }

// const userReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case "CHANGE_USERNAME":
//       return {
//         ...state,
//         username: action.payload,
//       }
//     default:
//       return state
//   }
// }
// const store = createStore(userReducer)
// export default store;

import { configureStore } from '@reduxjs/toolkit'
import getstatus from './action'
//import filtersReducer from './action'

const store = configureStore({
  reducer: {
    status: getstatus
  //  filters: filtersReducer,
  },
})

export default store