import React, { useState, useEffect } from 'react'
import { useSearchParams, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import { useCookies } from 'react-cookie';
import { MdOutlineDone } from "react-icons/md";
import { MdSmsFailed } from "react-icons/md";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import Card from 'react-bootstrap/Card';

const RegistrationConfirm = () => {

    let navigate = useNavigate();

    const [cookies] = useCookies(['XSRF-TOKEN']);
    const [searchParams] = useSearchParams();
    const [show, setShow] = useState(false)
    const [expired, setExpired] = useState(false)



    const [t] = useState({
        token: searchParams.get("token")
    });


    //const emailRef = useRef(null);


    useEffect(() => {
        document.title = 'Контакт–Центр м. Запоріжжя 1580';
        window.scrollTo(0, 0);

        const handleAcceptClick = async () => {

            const url = "/api/auth/registrationConfirm";

            try {
                let response = await fetch(url, {
                    method: "POST",
                    body: JSON.stringify(t),
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-XSRF-TOKEN': cookies['XSRF-TOKEN']
                    }
                });

                const data = await response.json();

                if (response.status === 200) {

                    if (data.message === 'expired') {
                        setExpired(true)

                    }

                    if (data.message === 'valid') {
                        setShow(true)

                    }

                    if (data.message === 'invalidToken') {
                        navigate("/");

                    }

                } else {
                    navigate("/");
                }
            } catch (err) {
                navigate("/");
            }
        }

        handleAcceptClick()

    }, [])


    return (
        <>
            <Container>
                {/* <Page /> */}
                <Card className='shadow-sm mt-4 mb-5 bg-body rounded'>
                    <Card.Body>
                        <span className="p-3"></span>
                        <div className="block pt-5">
                            {show &&

                                <div className="d-flex justify-content-center row">
                                    <Row>
                                        <div className="col">
                                            <span className="rememberPaswordResult"><MdOutlineDone /></span>
                                        </div></Row>
                                    <Row>  <div className="col rememberPaswordResult2 pt-3">
                                        <b>Підтвердження Вашого Email виконано успішно!</b>
                                        <div className='py-3'>
                                            Зараз ви можете прейти до особистого кабінету
                                        </div>
                                    </div>
                                    </Row>
                                </div>


                            }

                            {expired &&


                                <div className="d-flex justify-content-center row">
                                    <Row>
                                        <div className="col">
                                            <span className="fail-email-conf"><MdSmsFailed /></span>
                                        </div></Row>
                                    <Row>  <div className="col rememberPaswordResult2 pt-3">
                                        <b>Срок дії (12 годин) підтвердження Вашої регістрації закінчівся!</b>
                                        <div className='py-3'>
                                            Зараз ви можете повторно пройти регістрацію
                                        </div>
                                    </div>
                                    </Row>
                                </div>

                            }
                        </div>
                        {(show || expired) &&
                            <Row className="py-1">
                                <Col className="d-flex justify-content-center">
                                    <Button variant="primary" onClick={() => { navigate("/") }}>Ok

                                    </Button>{''}
                                </Col></Row>
                        }

                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}

export default RegistrationConfirm