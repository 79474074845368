function Pagetitle({title}) {
   
    return (
        <>

            <h2 class="pb-2 border-bottom mb-4 mt-4">{title}</h2>

        </>

    );
}

export default Pagetitle;