import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import React, { useState } from 'react'
import Spinner from "react-bootstrap/Spinner";
import Container from 'react-bootstrap/Container';
import AuthService from "../../../services/AuthService";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import messages from "./messages.json";
import { MdOutlineDone } from "react-icons/md";
import { Col, Row } from 'react-bootstrap';

const DeleteUser = ({ show, onHide: closeModal, onExit }) => {

    let navigate = useNavigate();
    const [confirm, setConfirm] = useState(false);
    const [spiner, setSpiner] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [success, setSuccess] = useState(false);
    const [alert_text, setAlert_text] = useState(messages.deleteuser_error);

    const handleSubmit = () => {
        setSpiner(true);
        setShowAlert(false)
        setConfirm(false);

        const user = AuthService.getCurrentUser();

        if (user) {

            axios.post("/api/auth/loschenuser", {}
            ).then((response) => {
                if (response.status === 200) {
                    if(response.data.message === "ok"){
                       // AuthService.logout();
                       localStorage.removeItem("user");
                        setSuccess(true)
                    } else{
                        setAlert_text(response.data.message)
                        end()
                    }
        
                } else{
                    end()
                }
            }).catch(error => {
                       end()
            })

        } else{
                        setAlert_text(messages.deleteuser_error2)
                        end()
        }
    }

    const end = ()=>{
                        setShowAlert(true)
                        setSpiner(false);
                        setConfirm(true);
    }

    onExit=() => {
        
        if (success){
            navigate("/")
        }
        else{
            setConfirm(false);
            setSpiner(false);
            setShowAlert(false)
        }
     
    }

    const nextSteep = () => {
        closeModal()
        navigate("/")

    }

    return (
        <>
            <Modal show={show} onHide={closeModal} onExit={onExit}
                aria-labelledby="deleteUser" fullscreen='md-down'
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Видалення облікового запису</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                    {!success && <>
                        <div className="text-start" style={{fontSize: '14px'}}>
                         {messages.deleteuser_info}
                         </div>
                   
               <Row>
                     <div class="col d-flex align-items-center cp p-2" >
                     <section class="text-center p-3">
                                    <input
                                        disabled={spiner}
                                        onClick={() => setConfirm(!confirm)}
                                        className="form-check-input form-check-input_req  me-1 cp" type="checkbox"
                                        id="deleteUser_checkbox">
                                       </input>
                                </section>

                                <div className='me-auto py-3'>
                                    <label className="form-check-label cp" for="deleteUser_checkbox">{messages.deleteuser_confirm_message}</label>
                                </div>
                     </div>
                    
                    </Row>
                    <div className="d-flex justify-content-center pb-3">
                    <Button id="confirm_deleteuser_b" variant="primary" type="submit" onClick={() => {handleSubmit()}}
                    disabled={!confirm}>
                                    {spiner ? (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                       <>{messages.deleteuser_sub_buttom_message}</>
                                    )}
                                </Button>{' '}         
                    </div>
                    <Alert className="text-center loginFormAlert" show={showAlert} variant='danger'>{alert_text}</Alert>

                    </>}

                    {success &&
                    <div className="d-flex justify-content-center row">
                                    <Row>
                                        <div className="col">
                                            <span className="rememberPaswordResult"><MdOutlineDone /></span>
                                        </div></Row>
                                    <div className="col rememberPaswordResult2 pt-3 pb-4">
                                        {messages.deleteuser_success_message}
                                    </div>

                                    <Row className="py-2">
                                    <Col className="d-flex justify-content-center">
                                        <Button className="login_modal_button3 py-2" variant="primary" type="" onClick={()=> nextSteep()}
                                        >
                                            Закрити форму
                                        </Button>{' '}
                                    </Col>
                                </Row>
                                    
                                </div>
}
</Container>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DeleteUser