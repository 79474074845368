import React from "react";
const FormattedText = (props) => {
  return (
    <div className={props.className}>
      {props.text.map((textItem) => {
        switch (textItem.type) {
          case "bold":
            return <b>{textItem.value}</b>;
          case "link":
            return (
              <a href={textItem.href} target={textItem.target}>
                {textItem.value}
              </a>
            );
          default:
            return textItem.value;
        }
      })}
    </div>
  );
};

export default FormattedText;
