import Container from 'react-bootstrap/Container';
import Page from '../page';
import Row from 'react-bootstrap/Row';
import Pagetitle from '../Pagetitle';
import Image from 'react-bootstrap/Image';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import { Card } from 'react-bootstrap';
import data from "./dataTechupport.json";
import { MdOutlineOndemandVideo } from "react-icons/md";
import TechsupportModal from './form/techsupportModal';

function TechSupport() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Технічна підтримка – Контакт–Центр м. Запоріжжя 1580';
    }, []);

    const [showTechsupportModal, setShowTechsupportModal] = useState(false);

    return (
        <>
          <TechsupportModal
                show={showTechsupportModal}
                onHide={()=>{setShowTechsupportModal(false)}}
            />
            <Container>
                <Page />
                <Pagetitle title={'Технічна підтримка'} />

                    

                    
        
                <h4 className='pt-3 pb-3 p-2'>
          Якщо ви зіткнулися з проблемами при використанні нашого сайту, будь ласка, спробуйте виконати наступні кроки:
        </h4>
      <Card>
      <Card.Body>
        <ul style={{ listStyleType: 'none', paddingLeft: 0 }} className='p-2'>
          <li style={{ margin: '10px 0'}}>
            <h4 className="card-title">1. Оновіть ваш браузер до останньої версії:</h4><p className='p-2'>Для коректної роботи сайту важливо використовувати актуальну версію браузера. Оновлення браузера забезпечить сумісність з новими технологіями та дозволить уникнути багатьох помилок. Щоб оновити браузер, перейдіть у налаштування вашого браузера та перевірте наявність оновлень.</p>
          </li>
          <li style={{ margin: '10px 0'}}>
          <h4 className="card-title">2. Очистіть кеш браузера:</h4><p className='p-2'>Іноді застарілий кеш може спричиняти проблеми з відображенням сторінок або функціональністю сайту. Очистка кешу допоможе отримати найсвіжіші дані з сайту.</p>
           
          </li>
          <li style={{ margin: '10px 0' }}>
          <h4 className="card-title">3. Вимкніть блокувальники реклами:</h4><p className='p-2'>Якщо у вас встановлені розширення для блокування реклами, вони можуть перешкоджати завантаженню деяких елементів сайту. Спробуйте тимчасово вимкнути блокувальник реклами і перевірте, чи вирішує це проблему. Для цього зазвичай потрібно натискати на іконку блокувальника реклами у верхньому правому куті браузера та вибрати опцію "Вимкнути для цього сайту".</p>
          </li>
        </ul>
        <div style={{
        marginTop: '20px',
        padding: '25px',
        backgroundColor: '#f8faf7',
        border: '1px solid #aac5aa',
        borderRadius: '5px'
      }}>
        Ці прості кроки можуть допомогти виправити більшість проблем із сайтом. Якщо проблема не зникла, зверніться до нашої <span onClick={()=> setShowTechsupportModal(true)} style={{color: '#028B42'}} className='cp'><u>технічної підтримки</u></span> для подальшої допомоги.
      </div>
        </Card.Body>
      </Card>

     

      
   
                
       

                <h4 className='pt-5 p-2'> Якщо не можете зареєструватися, прочитайте відповіді
                    на найпоширеніші запитання. Це може допомогти вирішити Вашу проблему.
                </h4>

                {data.map(item => (
                    <>
                        <Card key={item.id} className="mt-4 mb-5" >
                            <Card.Body>
                                <Row>
                                    <Col sm={12} md={2} className="d-flex align-items-center justify-content-center">
                                        <Image src={'/' + item.id + 'techsup.png'} alt={item.id + 'techsup.png'}></Image>
                                    </Col>
                                    <Col sm={12} md={10} className="pt-md-0 pt-4">
                                       
                                        {item.id === 5 ?
                                         <h4 className="card-title">{item.id + '. '}{item.title+ ' '}<span onClick={()=> setShowTechsupportModal(true)} style={{color: '#028B42'}} className='cp'><u>нашій технічній підтримці</u></span></h4> 
                                        :
                                         <h4 className="card-title">{item.id + '. '}{item.title}</h4>}

                                        <ul className="list-group list-group-flush">
                                            {item.text2.map((text2, index) => (<>
                                                <li className="list-group-item border-0" key={index} >{text2}</li>
                                            </>
                                            ))}
                                        </ul>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                        

                    </>))}
                    <Row className="alert-message alert-message-success cursorp" onClick={()=>window.open('https://www.youtube.com/watch?v=9I6oRXIwO3k', '_blank')}>
                   
                   <Col sm={12} md={1} className="d-flex align-items-center justify-content-center">
                  
                   <span style={{  color: '#028B42',
 display: 'flex',
 width: '45px',
 height: '45px',
 justifyContent: 'center',
 alignItems: 'center',
 }}>
                               <MdOutlineOndemandVideo size={70}/>
                           </span>
                   
                       </Col>
                      
                       <Col sm={12} md={11} className="d-flex align-items-center pt-md-0 pt-4" style={{fontSize: '1.3rem', color:'#028B42'}}>
                           <u>Відеоінструкція реєстрації на веб-порталі та з'єднання з Telegram ботом "Запоріжжя 1580"</u>
                       </Col>
                   
                   </Row>
            </Container>
        </>
    );
}

export default TechSupport;

