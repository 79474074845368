
import axios from "axios";

class MapServive {

    getMap = (id, dates, datef) => {
        return axios.post('/api/map/problems', {
            id,
            dates,
            datef
        });
    }

    getMapProblem = (id) => {
        return axios.post('/api/map/problem/' + id, {});
    }
}

const mapServiceInstance = new MapServive();

export default mapServiceInstance; 
