const popupContent = {
    textAlign: "left",
    // height: "350px",
    marginTop: "0px"
  };
  const popupHead = {
    fontWeight: "bold",
    fontSize: "22px"
  };
  
  const popupText = {
    fontSize: "15px",
    marginBottom: "5px"
  };
  
  const okText = {
    fontSize: "15px"
  };


  export { popupContent, popupHead, popupText, okText };