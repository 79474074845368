import Dropdown from 'react-bootstrap/Dropdown';
import { FaChevronDown } from "react-icons/fa";
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';



function Page() {
    let navigate = useNavigate();
    return (
        <>

            <Row className='align-items-center' id="logopage">

                <div class="d-grid  gap-lg-5 gap-3 d-lg-flex justify-content-center">

                    <button type="button" class="btn btn-secondary px-4 shadow-sm" onClick={() => { navigate('/remontworks') }}>Ремонтні роботи</button>
                    <button type="button" class="btn btn-secondary px-4 shadow-sm" onClick={() => { navigate('/requests') }}>Звернення мешканців</button>
                    <Dropdown align="center" id='drmainbutton'>
                        <Dropdown.Toggle variant="secondary" className='logomain-right-button bbb shadow-sm'>
                            Результати роботи<FaChevronDown className='main-button2' />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='shadow-sm rounded'>
                            <Dropdown.Item className='p-2' onClick={() => { navigate('/result') }}>Статистика</Dropdown.Item>
                            <Dropdown.Item className='p-2' onClick={() => { navigate('/news') }}>Новини</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </Row>

        </>

    );
}

export default Page;