import Container from 'react-bootstrap/Container';
import Page from '../page';
import Row from 'react-bootstrap/Row';
import React, { useState, useEffect } from "react"
import Pagetitle from '../Pagetitle';
import Col from 'react-bootstrap/Col';
import CountUp from 'react-countup';
import axios from "axios";
import Select from 'react-select';
import period from './periods.json';
import CharHeader from './charHeader';
import ReactECharts from 'echarts-for-react';
import title from './title.json';


function Result() {

  const resultApi = "/api/result/";

  const [windowWidth, setWindowWidth] = useState(Math.round(window.innerWidth - (window.innerWidth * 0.25)));
  const [topforProblemLegend, setTopforProblemLegend] = useState('60%');
  const [radiusforProblemLegend, setRadiusforProblemLegend] = useState('32%');
  const [widthProblemLegend, setWidthProblemLegend] = useState(500);
  const [rotare, setRotare] = useState(45);
  const [width, setWidth] = useState(window.innerWidth)

  const [selectedPeriod, setSelectedPeriod] = useState(period[0]);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    
    document.title = 'Статистика – Контакт–Центр м. Запоріжжя 1580';
    // window.scrollTo(0, 0);

    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);

  }, []);

  useEffect(() => {

    if (width > 500) {
      setTopforProblemLegend('70%')
      setRadiusforProblemLegend('39%')
      setRotare(45)

    } else {
      setTopforProblemLegend('50%')
      setRadiusforProblemLegend('27%')
      setRotare(90)
    }

    if (width < 1200) {
      setWidthProblemLegend(350)
    } else {
      setWidthProblemLegend(500)
    }
    setWindowWidth(Math.round(width - (width * 0.25)))

  }, [width]);


  const [block1, setblock1] = useState([]);
  const [block2, setblock2] = useState([]);
  const [block1Param, setBlock1Param] = useState({ new: 0, phone: 0, online: 0, other: 0 });
  const [block2Param, setBlock2Param] = useState({ in_work: 0, executed: 0, total: 0 });

  const [problem, setProblem] = useState([]);

  const [rn_name, setRn_name] = useState([]);
  const [rn_req, setRn_req] = useState([]);

  const [day_name, setDay_name] = useState([]);
  const [day_req, setDay_req] = useState([]);


  const option_a = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)'

    },
    legend: {
      orient: 'vertical',
      right: 5,
      bottom: -5,
      textStyle: {
        width: widthProblemLegend,
        overflow: "break",
        fontSize: 15,
        padding: [1, 1, 1, 1],
        lineHeight: 20
      }
    },

    label: {
      formatter: '{b|{c}}\n{per|{d}%}',

      rich: {
        b: {
          color: '#4C5058',
          fontSize: 18,
          fontWeight: 'bold',
          lineHeight: 15
        },
        per: {
          padding: [2, 0],
          fontSize: 16,
        }
      }
    },
    series: [
      {
        center: ['30%', '55%'],
        name: '',
        type: 'pie',
        radius: '70%',
        data: problem,

        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };

  const option_a_mob = {
    tooltip: {
      trigger: 'item',

      formatter: function (params) {
        return `<div style="
            max-width: 200px; word-wrap: break-word; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
            ">${params.name}</div>`;
      }

    },
    legend: {
      orient: 'vertical',
      top: topforProblemLegend,
      textStyle: {
        width: windowWidth,
        overflow: "break",
        fontSize: 16,
        lineHeight: 20
      }
    },

    label: {
      formatter: '{b|{c}}\n{per|{d}%}',

      rich: {
        b: {
          color: '#4C5058',
          fontSize: 14,
          fontWeight: 'bold',
        },
        per: {
          fontSize: 13,
        }
      }
    },
    series: [
      {
        center: ['50%', radiusforProblemLegend],
        name: '',
        type: 'pie',
        radius: '50%',
        data: problem,

        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };

  const firstchart = {
    tooltip: {
      formatter: '{b}:</br><b>{c}</b>',
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    grid: {
      left: '1%',
      right: '1%',
      bottom: '3%',
      containLabel: true
    },

    xAxis: {
      type: 'category',
      splitLine: {
        show: true
      },
      axisLabel: {
        rotate: rotare,
        fontSize: 15,
        margin: 15,
      },
      // boundaryGap: false,
      data: block1
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        label: {
          show: true,
          position: 'top'
        },
        data: block2,
        type: 'line',
        lineStyle: {
          color: '#028b42'
        },

      }
    ]
  };

  const configPerday = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },

    legend: {
      data: ['Прийнято звернень'],
    },

    xAxis: {
      type: 'category',
      splitLine: {
        show: true
      },
      axisLabel: {
        rotate: rotare,
        margin: 10,
      },
      boundaryGap: false,
      data: day_name
    },
    yAxis: {
      type: 'value',
      min: function (value) {
        return Math.floor(value.min);
      },
      max: function (value) {
        return Math.ceil(value.max + 1);
      }
    },
    series: [
      {
        label: {
          show: true,
          position: 'top'
        },
        data: day_req,
        type: 'line',
        lineStyle: {
          color: '#028b42'
        },
      }
    ]
  };

  const configPerday_day = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },

    legend: {
      data: ['Прийнято звернень'],
    },

    xAxis: {
      type: 'category',
      splitLine: {
        show: true
      },
      axisLabel: {
        rotate: rotare,
        margin: 15,
      },
      boundaryGap: false,
      data: day_name
    },
    yAxis: {
      type: 'value',
      interval: 10,
      min: function (value) {
        return Math.floor(value.min);
      },
      max: function (value) {
        return Math.ceil(value.max + 1);
      }
    },
    series: [
      {
        label: {
          show: true,
          position: 'top'
        },
        data: day_req,
        type: 'line',
        lineStyle: {
          color: '#028b42'
        },
      }
    ]
  };

  const data_rn = {
    // tooltip: {
    //   trigger: 'axis',
    //   axisPointer: {
    //     type: 'shadow'
    //   }
    // },
    tooltip: {
      formatter: '{b}:</br><b>{c}</b>',
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    grid: {
      left: '0%',
      right: '6%',
      bottom: '10%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: rn_name,

      axisLabel: {
        rotate: 90,
        margin: 10,
        fontSize: 15,
      }
    },
    yAxis: {
      type: 'value',

      show: false,
      min: 1,
      max: function (value) {
        return Math.ceil(value.max + 1);
      }
    },
    series: [
      {
        label: {
          show: true,
          position: 'top'
        },
        itemStyle: {
          color: '#028b42'
        },
        data: rn_req,
        type: 'bar'
      }
    ]
  };

  const loadChartData = async (url) => {
    const response = await axios.post(url, {});
    return response.data;
  }

  useEffect(() => {

    setProblem([]);
    setBlock2Param({ in_work: 0, executed: 0, total: 0 });
    setRn_name([]);
    setRn_req([]);
    setDay_req([]);
    setDay_name([]);

    const fetchDataProblem = async () => {
      const data = await loadChartData(resultApi + selectedPeriod.url_problem);

      for (let i = 0; i < data.length; i++) {
        setProblem(prevItems => [...prevItems, { name: data[i].p_name, value: parseInt(data[i].req_total) }])
      }

    };

    const fetchDataRn = async () => {
      const data = await loadChartData(resultApi + selectedPeriod.url_rn);

      for (let i = 0; i < data.length; i++) {

        setRn_name(prevItems => [...prevItems, data[i].district_name])
        setRn_req(prevItems => [...prevItems, parseInt(data[i].req_total)])
      }
    };

    const fetchDataPerDay = async () => {
      const data = await loadChartData(resultApi + selectedPeriod.url_perDay_day);

      for (let i = 0; i < data.length; i++) {
        if (selectedPeriod.value === '3') {
          const date = data[i].req_date.split(' ')[1].split(':')[0];
          setDay_name(prevItems => [...prevItems, date])
        } else {
          const date = data[i].req_date.split(' ')[0].split('-')[2] + '.' + data[i].req_date.split(' ')[0].split('-')[1] + '.' + data[i].req_date.split(' ')[0].split('-')[0];
          setDay_name(prevItems => [...prevItems, date])
        }

        setDay_req(prevItems => [...prevItems, parseInt(data[i].req_total)])
      }
    };

    const fetchDataBlock2 = async () => {
      const data = await loadChartData(resultApi + selectedPeriod.to_bloks);

      setBlock2Param({ total: data[0].total, in_work: data[0].in_work, executed: data[0].executed })

    };

    fetchDataBlock2();
    fetchDataRn();
    fetchDataPerDay();
    fetchDataProblem();

  }, [selectedPeriod]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await loadChartData(resultApi+"block1");

      for (let i = 0; i < data.length; i++) {
        setblock2(prevItems => [...prevItems, parseInt(data[i].total)])
        setblock1(prevItems => [...prevItems, data[i].month_NAME + ' ' + data[i].year])
        setBlock1Param(prevItems => ({ ...prevItems, new: prevItems.new + data[i].total, phone: prevItems.phone + data[i].phone, online: prevItems.online + data[i].e_REQ, other: prevItems.other + data[i].other }))
      }
    };

    fetchData();

  }, []);

  return (
      <Container>
        <Page />
        <Pagetitle title={'Статистика'} />
        <div className='pb-4 mb-4'>
          <div class="p-3">
            <section className="pt-1">
              <h3 className='fw-normal'>Загальна інформація</h3>
              <span style={{ fontSize: '13px' }}>(За останні 12 місяців)</span>
            </section>
          </div>
          <div className='col-sm-12 d-flex justify-content-center pb-5 pt-4'>
            <div className='row w-100 d-flex justify-content-center'>
              <Col sm={6} md={6} xl={3} className="pb-4 pe-3 ps-3">
                <div class="card card-stat">
                  <div class="card-body">
                    <div class="row">
                      <div class="col mt-0">
                        <div class="card-title-results nowrap text-center">{title.t1}</div>
                      </div>
                    </div>
                    <h1 class="mt-1 mb-1 text-center card-title-results-d"><CountUp end={block1Param.new} separator="" decimal="" /></h1>
                  </div>
                </div>
              </Col>
              <Col sm={6} md={6} xl={3} className="pb-4 pe-3 ps-3">
                <div class="card card-stat">
                  <div class="card-body">
                    <div class="row">
                      <div class="col mt-0">
                        <div class="card-title-results nowrap text-center">{title.t2}</div>
                      </div>
                    </div>
                    <h1 class="mt-1 mb-1 text-center card-title-results-d"><CountUp end={block1Param.phone} separator="" decimal="" /></h1>
                  </div>
                </div>
              </Col>
              <Col sm={6} md={6} xl={3} className="pb-4 pe-3 ps-3">
                <div class="card card-stat">
                  <div class="card-body">
                    <div class="row">
                      <div class="col mt-0">
                        <div class="card-title-results nowrap text-center">{title.t3}</div>
                      </div>
                    </div>
                    <h1 class="mt-1 mb-1 text-center card-title-results-d"><CountUp end={block1Param.online} separator="" decimal="" /></h1>
                  </div>
                </div>
              </Col>
              <Col sm={6} md={6} xl={3} className="pb-4 pe-3 ps-3">
                <div class="card card-stat">
                  <div class="card-body">
                    <div class="row">
                      <div class="col mt-0">
                        <div class="card-title-results nowrap text-center">{title.t4}</div>
                      </div>
                    </div>
                    <h1 class="mt-1 mb-1 text-center card-title-results-d"><CountUp end={block1Param.other} separator="" decimal="" /></h1>
                  </div>
                </div>
              </Col>
            </div>
          </div>
          <div className='py-2'>
            <CharHeader title={title.chart1} subtitle='прийнято звернень' />
          </div>
          <ReactECharts option={firstchart} style={{ height: '400px' }} />
        </div>
        <div className='pb-4 mb-4'>
          <div class="card-header">
            <section className="pt-2"><h3 className='fw-normal'>Статистична інформація</h3>
            </section>
          </div>
          <div className="pt-3 ps-0 ps-sm-3 d-flex justify-content-center d-flex justify-content-sm-start">
            <div class="col-10">
              <Select
                options={period}
                classNamePrefix="mySelect"
                defaultValue={selectedPeriod}
                onChange={(e) => setSelectedPeriod(e)}
                styles={{
                  option: (styles, state) => ({
                    ...styles,
                    fontSize: state.isSelected ? 21 : 19,
                    color: state.isSelected ? 'black' : '#444444'
                  }),
                }}

                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#E1E4E8',
                    primary: '#c2e6d3',
                  },
                })}
              />
            </div>
          </div>

          <div className='row flex justify-content-center py-5 ps-3 pe-3'>
            <Col md={4} xxl={3} className="pb-4">
              <div className="card card-stat  card-stat-2 h-100 pb-2">
                <div className="card-body position-relative">
                  <div className="row pb-5">
                    <div className="col mt-0">
                      <div className="card-title-results text-center">{title.t5}</div>
                    </div>
                  </div>
                  <div className="position-absolute bottom-0 start-50 translate-middle-x">
                    <h1 className="mt-4 mb-1 text-center card-title-results-d"><CountUp end={block2Param.total} separator="" decimal="" /></h1>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={4} xxl={3} className="pb-4">
              <div className="card card-stat h-100 pb-2">
                <div className="card-body position-relative">
                  <div className="row pb-5">
                    <div className="col mt-0">
                      <div className="card-title-results text-center">{title.t6}</div>
                    </div>
                  </div>
                  <div className="position-absolute bottom-0 start-50 translate-middle-x">
                    <h1 className="mt-4 mb-1 text-center card-title-results-d"><CountUp end={block2Param.executed} separator="" decimal="" /></h1>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={4} xxl={3} className="pb-4">
              <div className="card card-stat-3 card-stat h-100 pb-2">
                <div className="card-body position-relative">
                  <div className="row pb-5">
                    <div clclassNameass="col mt-0">
                      <div className="card-title-results text-center">{title.t7}</div>
                    </div>
                  </div>
                  <div className="position-absolute bottom-0 start-50 translate-middle-x">
                    <h1 className="mt-4 mb-1 text-center card-title-results-d"><CountUp end={block2Param.in_work} separator="" decimal="" /></h1>
                  </div>
                </div>
              </div>
            </Col>
          </div>


          <Row className='py-3'>
            <Col md={12} sm={12} lg={6} className='py-1'>
              {selectedPeriod.value !== '3' && <>
                <CharHeader title={title.chart2} subtitle={selectedPeriod.label} />
                <ReactECharts option={configPerday} style={{ height: '400px' }} />
              </>}

              {selectedPeriod.value === '3' && <>
                <CharHeader title={title.chart2_1} subtitle={selectedPeriod.label} />
                <ReactECharts option={configPerday_day} style={{ height: '400px' }} />
              </>}

            </Col>
            <Col md={12} sm={12} lg={6} className='py-5 py-lg-1'>
              <CharHeader title={title.chart3} subtitle={selectedPeriod.label} />
              <ReactECharts option={data_rn} style={{ height: '400px' }} />
            </Col>
          </Row>
          <Col className='py-4 m2'>

          <CharHeader title={title.chart4} subtitle={selectedPeriod.label} />
            <ReactECharts option={option_a} style={{ height: '400px' }} />
          </Col>

          <Col className='py-1 m1'>
          <CharHeader title={title.chart4} subtitle={selectedPeriod.label} />
            <ReactECharts option={option_a_mob} style={{ height: '750px' }} />
          </Col>
        </div>
      </Container >
  );
}

export default Result;