import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import RegistrationModal from "../Registartion_modal";
import alarmBloks from "./alarmBloks";



function Way() {

    const [showRegModal, setShowRegModal] = useState(false);
    const handleCloseLoginModalR = () => setShowRegModal(false);

    const handleUrlbot = () => {
        window.open('https://t.me/colcentrebot', '_blank');

    }

    return (
        <>

            <RegistrationModal
                show={showRegModal}
                onHide={handleCloseLoginModalR}
            />

            <Container>
                <section className='text-center py-3 py-md-4'><h2>Способи подачі звернення</h2></section>
                <alarmBloks.alarm2/>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                    <Nav className="nav-scroller nav nav-pills nav-justified mb-5">
                        <Nav.Link eventKey="first" className='ways-transfer'>Через інтернет-портал
                        </Nav.Link>
                        <Nav.Link eventKey="second" className='ways-transfer'>
                            Зателефонувати
                        </Nav.Link>
                        <Nav.Link eventKey="third" className='ways-transfer'>
                            Через чат-бот
                        </Nav.Link>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <Container>
                                <div className="row">

                                    <Col>
                                        <h3>Інтернет-портал</h3>
                                        <p>
                                            <ul className="list-group listp1">
                                                <li>Онлайн-реєстрація звернень мешканців;</li>
                                                <li>Особистий кабінет мешканця;</li>
                                                <li>Повідомлення про планові або аварійні роботи в місті;</li>
                                                <li>Карта звернень від мешканців міста;</li>
                                                <li>Результати роботи комунальних служб міста по зверненням мешканців,
                                                    загальна статистика за результатими робіт;
                                                </li>
                                            </ul>
                                        </p>
                                        <Button variant="success" className='py-1'
                                                onClick={() => setShowRegModal(true)}>
                                            Зареєструватися
                                        </Button>

                                    </Col>
                                </div>
                            </Container>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <Container>
                                <div className="row">

                                    <Col>
                                        <h3>Контакт-центр 1580</h3>
                                        <p>Міська багатоканальна телефонна служба,
                                            куди кожен мешканець міста може подати звернення, або отримати потрібну
                                            консультацію. Фахівці Контакт Центру перевіряють факт та якість виконання
                                            звернення шляхом опитування заявників в телефонному режимі за зверненням
                                            шляхом отримання зворотнього зв’язку від заявників.
                                            Зателефонувати можна за номерами
                                            телефонів:
                                            <ul className="list-group listp1 py-2">
                                                <li>15-80 (зі стаціонарних)</li>
                                                <li>(061) 787 15 80</li>
                                                <li>(050) 414 15 80</li>
                                                <li>(067) 656 15 80</li>
                                            </ul></p>
                                    </Col>
                                </div>
                            </Container>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            <Container>
                                <div className="row">

                                    <Col>
                                        <h3>Чат-бот «Запоріжжя 1580»</h3>
                                        <p>
                                            <ul className="list-group listp1">
                                                <li>Повідомлення про планові або аварійні роботи за вашими адресами;</li>
                                                <li>Довідкова інформація про контакти та режим роботи виконавчих органів, комунальних підприємств міської ради;</li>
                                                <li>Подача звернень мешканців;</li>
                                            </ul>
                                        </p>
                                        <Button variant="success" className='py-1' onClick={handleUrlbot}>
                                        Telegram бот «Запоріжжя 1580»
                                        </Button>
                                    </Col>
                                </div>
                            </Container>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </Container>

        </>

    );
}

export default Way;