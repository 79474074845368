import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SmoothCollapse from 'react-smooth-collapse'
import React, { useEffect, useState } from "react";
import { scroller } from 'react-scroll'
import axios from "axios";
import { ThreeDots } from 'react-loader-spinner'

function Whattoreport() {


    const [ex, setEx] = useState(false);
    const [showspiner, setShowspiner] = useState(true);
    const [data, setData] = useState([]);

    const xxxx = () => {

        if (ex) {
            scroller.scrollTo('whatn', {
                duration: 10,
                delay: 0,
                smooth: true,
            })
            setEx(!ex)
        } else {
            setEx(!ex)
        }

    }

    useEffect(() => {
        axios.post("/api/general/getProblem_main", {}
        ).then((response) => {
            setShowspiner(false)
            setData(response.data)
        }).catch(error => {
        })
    }, []);

    return (
        <Container className='py-4'>
            <section className='text-center py-3 py-md-5' id="whatn">
                <h2>З якими питаннями можна звернутися до <span className='nowrap'>Контакт-Центру</span></h2>
            </section>

            {showspiner &&
                <div className='d-grid d-flex justify-content-center p-5'>

                    <ThreeDots
                        height="140"
                        width="140"
                        radius="15"
                        color="#4fa94d"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />

                </div>
            }
            {!showspiner && <>
                <div className='m2'>
                    <Row>
                        {data && data.sort((a, b) => a.id > b.id ? 1 : -1).map((d, index) => (
                            <Col lg={2}
                                key={index}>
                                {index > 11
                                    ? <SmoothCollapse expanded={ex}>
                                        <div className='img-problem_wrap'>
                                            <img src= {'/' + d.id + '.webp'} height={80} width={80} class="img-fluid" alt={"logo_m"+d.id}></img>
                                            <div className='title_text__icon'>
                                                {d.name}
                                            </div>
                                        </div>
                                    </SmoothCollapse>
                                    :
                                    <div className='img-problem_wrap'>
                                        <img src= {'/' + d.id + '.webp'} height={80} width={80} class="img-fluid" alt={"logo_m"+d.id}></img>
                                        <div className='title_text__icon'>
                                            {d.name}
                                        </div>
                                    </div>
                                }
                            </Col>
                        ))}
                    </Row>
                </div>
                <div className='m1'>
                    <Row>
                        {data && data.sort((a, b) => a.id > b.id ? 1 : -1).map((d, index) => (
                            <Col lg={2}
                                key={index}>
                                {index > 2
                                    ? <SmoothCollapse expanded={ex}>
                                        <div className='img-problem_wrap'>
                                            <img style={{marginRight: '15px'}} src= {'/' + d.id + '.webp'} height={83} width={83} class="img-fluid" alt={'problem' + d.id}></img>
                                            <div className='title_text__icon'>
                                                {d.name}
                                            </div>
                                        </div>
                                    </SmoothCollapse>
                                    :
                                    <div className='img-problem_wrap'>
                                        <img style={{marginRight: '15px'}} src= {'/' + d.id + '.webp'} height={83} width={83} class="img-fluid" alt={'problem' + d.id}></img>
                                        <div className='title_text__icon'>
                                            {d.name}
                                        </div>
                                    </div>
                                }
                            </Col>
                        ))}
                    </Row>
                </div>
            </>
            }
            <section className='text-center textx'><h3 onClick={() => xxxx()} value={ex ? 'Hide' : 'Show'}>{ex ? 'Показати менше' : 'Показати більше'}</h3></section>
        </Container>

    );
}

export default Whattoreport;