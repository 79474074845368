import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import React, { useState } from 'react'
import Spinner from "react-bootstrap/Spinner";
import Container from 'react-bootstrap/Container';
import axios from "axios";
import { Alert } from 'react-bootstrap';
import { MdOutlineDone } from "react-icons/md";
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ReCAPTCHA from "react-google-recaptcha";


const TechsupportModal = ({ show, onHide: closeModal, onExit }) => {


    const recaptchaRef = React.createRef();
    const [showError, setShowError] = useState(false);
    const [spiner, setSpiner] = useState(false);
    const [messagges, seMessagges] = useState('');
    const [contact, setContact] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [needBlockButton, setNeedBlockButton] = useState(false);


    const handleSubmit = () => {
        setShowError(false);
        
        if(messagges ==='' || messagges === null || contact ==='' || contact === null){
            setErrorMessage('Заповніть усі поля форми !');
            setShowError(true);
            return;
        }
        if(recaptchaRef.current.getValue() ==='' || recaptchaRef.current.getValue() === null){
            setErrorMessage('Натисніть на капчу !');
            setShowError(true);
            return;
        }
        
      setNeedBlockButton(true)
       setSpiner(true);

        axios.post("/api/general/reqToTechsupport", { messagges: messagges, contact: contact, token: recaptchaRef.current.getValue() }
            ).then((response) => {
                if (response.status === 200) {
                    if(response.data.message === "ok"){
                        setSuccess(true)
                    } 

                    if(response.data.message === "captcha"){
                        setErrorMessage('Помилка капчі !');
                        setShowError(true);
                    }
        
                } else{
                    setErrorMessage('Помилка сервера !');
                    setShowError(true);  
                }
                setNeedBlockButton(true)
            }).catch(error => {
                setErrorMessage('Помилка сервера !');
                setShowError(true);  
                setNeedBlockButton(true)      
            })


    }

   

    onExit=() => {
        setShowError(false);
        setSpiner(false);
        setContact('');
        seMessagges('');
        setSuccess(false);
        setNeedBlockButton(false);
    }

 

    return (
        <>
            <Modal show={show} onHide={closeModal} onExit={onExit}
                aria-labelledby="techsupportModal" fullscreen='md-down'
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>В технічну підтримку</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                    
                    {!success &&
                    <>
                    <Form>
                    <Form.Group className="mb-3" controlId="form.ControlTextarea1">
                        <Form.Label style={{fontSize: '12px'}}>Повідомити про технічні негаразди на веб-порталі:</Form.Label>
                        <Form.Control as="textarea" rows={3} max={2000} 
                        value={messagges}
                        onChange={(e) => seMessagges(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="form.ControlInput1">
                        <Form.Label style={{fontSize: '12px'}}>Ваш контакт зворотнього зв'язку(email або телефон):</Form.Label>
                        <Form.Control type="text" 
                            value={contact}
                            onChange={(e) => setContact(e.target.value)}
                            maxLength={100}
                        />
                    </Form.Group>
                   </Form>

                    <Row>
                   <Col className="d-flex justify-content-center pt-1 pb-3">
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey='6LfELHYoAAAAACYO1ngyjP8MbXoI7mTnriKL5Kup'
                                        onChange={() => (setShowError(false))}

                                    />
                     </Col>
                     <Alert className="text-center loginFormAlert" show={showError} variant='danger'>{errorMessage}</Alert>
                     </Row>
                  
                     <Row>
                     <Col className="d-flex justify-content-center">
                     <Button className="login_modal_button3 p-2" variant="primary" type="submit" onClick={() => {handleSubmit()}}
                     disabled={needBlockButton}
                    >
                                    {spiner ? (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                       <>Відправити</>
                                    )}
                                </Button>{' '} 
                                </Col>
                                </Row>

                                </>}

                                {success && <>
                                    <div className="d-flex justify-content-center row">
                                    <Row>
                                        <div className="col">
                                            <span className="rememberPaswordResult"><MdOutlineDone /></span>
                                        </div></Row>
                                    <div className="col rememberPaswordResult2 pt-3 pb-4" style={{fontSize: '16px'}}>
                                    Ваше повідомлення успішно направлено до служби технічної підтримки.
Для уточнення проблеми, з Вами зв'яжеться спеціаліст техпідтримки.< br/>
Часи роботи служби техпітримки: понеділок -п'ятниця з 8.00 до 17.00

                                    </div>
                                    
                                </div>
                                </>}
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TechsupportModal